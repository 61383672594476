"use client";

import { ICustomInputProps, isNumber } from "@/utils";
import { useField } from "formik";
import React, { useMemo, useState } from "react";
import { useAppSelector } from "@/store";
import { MultiSelect } from "./MultiSelect";

const CustomInput = ({
  name,
  label,
  labelAddOn,
  value,
  type,
  className,
  isRequired,
  titleCase,
  formikProps,
  inputClassName,
  onChange,
  inputRef,
  countryCode = false,
  countryCodeFieldName = "country",
  countryCodeClassName = "col-3",
  appendElement,
  ...props
}: ICustomInputProps) => {
  const { country_codes } = useAppSelector((state) => state.location);

  const [field, meta] = formikProps ? useField(name) : [null, null];
  const [internalValue, setInternalValue] = useState(value);

  const getCountryCodeOptions = useMemo(() => {
    return country_codes
      ?.filter(
        (element) =>
          element.phonecode !== null && element.phonecode !== undefined
      )
      ?.map((item) => ({
        phonecode: item.phonecode,
        country: item.iso || item.iso3,
        value: item.id,
        label: `${item.country} +${item.phonecode}`,
      }));
  }, [country_codes?.length]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueToSet = titleCase
      ? e.target.value.replace(/(^|\s)\S/g, (letter) => letter.toUpperCase())
      : e.target.value;
    setInternalValue(valueToSet);
    onChange?.(e);
    if (formikProps) {
      formikProps.setFieldValue(name, valueToSet, true);
    }
  };

  const formValue = formikProps ? field?.value : internalValue;
  const formError = formikProps ? meta?.touched && meta?.error : null;

  return (
    <div className={`form-group ${className}`}>
      <label className="d-flex align-items-center justify-content-between gap-1">
        <div className="d-flex gap-1">
          {label}
          {isRequired ? <span className="text-danger">*</span> : ""}
        </div>
        {labelAddOn}
      </label>
      <div
        className={`input-group ${
          countryCode ? "d-flex flex-row gap-2 align-items-center" : ""
        }`}
      >
        {((countryCode && isNumber(formValue) && formValue) ||
          countryCodeFieldName !== "country") && (
          <div className={`${countryCodeClassName}`}>
            <MultiSelect
              disabled={props.disabled}
              options={getCountryCodeOptions}
              formikProps={formikProps}
              name={countryCodeFieldName}
              small
              onChangeHandler={(e: any) =>
                formikProps?.setFieldValue(
                  countryCodeFieldName,
                  Array.isArray(e)
                    ? {
                        ...e[0],
                        value: e?.[0]?.value,
                        label: `${e?.[0]?.country} +${e?.[0]?.phonecode}`,
                      }
                    : [
                        {
                          ...e,
                          value: e.value,
                          label: `${e.country} +${e.phonecode}`,
                        },
                      ]
                )
              }
            />
          </div>
        )}
        <input
          {...field}
          {...props}
          ref={inputRef}
          type={type}
          pattern={type === "number" ? "[0-9]*" : undefined}
          value={formValue}
          onChange={handleChange}
          onWheel={(e: any) => {
            e.target.blur();
          }}
          className={`py-2 form-control rounded-lg ${
            inputClassName ? inputClassName : ""
          } ${countryCode ? "col-9" : ""}`}
        />
      </div>
      {appendElement}
      {formError ? (
        <div className="text-danger my-1 text-start">{meta?.error}</div>
      ) : null}
    </div>
  );
};

export { CustomInput };
