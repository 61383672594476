import {
  constructStructuredData,
  IFAQ,
  TProductDetails,
  IReview,
} from "@/utils";
import Script from "next/script";
import React from "react";

const StructuredData = ({
  faqs,
  productDetails,
  reviews,
  nonce,
  slug,
}: {
  faqs?: IFAQ[];
  productDetails?: TProductDetails;
  reviews?: IReview[];
  nonce: string | null;
  slug: string[];
}) => {
  const structuredData = constructStructuredData({
    slug: slug,
    type: slug?.length === 3 ? "Product" : "Static",
    faqs: slug.includes("faqs") ? faqs : [],
    productDetails: productDetails,
    reviews: reviews,
  });

  return (
    <React.Fragment>
      {structuredData && structuredData?.length > 0
        ? structuredData?.map((data, index) => (
            <Script
              key={`${slug.join("/")}-${index}`}
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(data),
              }}
              nonce={nonce || ""}
              strategy="beforeInteractive"
            />
          ))
        : null}
    </React.Fragment>
  );
};

export { StructuredData };
