"use client";

import Script from "next/script";
import React from "react";

const GoogleAnalytics = ({ nonce }: { nonce: string | null }) => {
  const gTagId = process.env.NEXT_PUBLIC_GTAG;

  if (!gTagId) {
    console.error(
      "Google Analytics ID is not defined in the environment variables."
    );
    return null;
  }

  return (
    <React.Fragment>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${gTagId}`}
        nonce={nonce || ""}
      />
      <Script
        id="google-analytics-config"
        strategy="lazyOnload"
        nonce={nonce || ""}
      >
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gTagId}', {
            page_path: window.location.pathname,
          });
        `}
      </Script>
    </React.Fragment>
  );
};

export { GoogleAnalytics };
