"use client";

import Image from "next/image";
import Link from "next/link";
import React, { useEffect } from "react";
import { strip_html_tags } from "@/utils";
import { useRouter } from "next/navigation";
import { setLoadingState, setOffCanvas, useAppDispatch } from "@/store";

const CategoryItem = ({
  item,
  index,
  hasDesc = false,
  widgetIndex,
}: {
  item: any;
  index: number;
  hasDesc: boolean;
  widgetIndex: number;
}) => {
  const dispatch = useAppDispatch();

  const router = useRouter();

  useEffect(() => {
    return () => {
      dispatch(setLoadingState(false));
    };
  }, []);

  return (
    <div
      className={`${
        widgetIndex ? "category-listing-item" : "compact-category-item"
      }`}
      onClick={() => {
        dispatch(setLoadingState(true));
        router.push(`/${item.link}`);
      }}
    >
      <figure className="img-hover-scale overflow-hidden">
        <Link
          href={item.link || "/"}
          onClick={() => {
            dispatch(setLoadingState(item.link !== "#"));
            dispatch(
              setOffCanvas({
                component: "",
                state: false,
              })
            );
          }}
        >
          <Image
            src={item?.img}
            alt={item.alt}
            width={200}
            height={200}
            className="img-fluid w-100 h-100"
            loading={index > 1 ? "lazy" : "eager"}
          />
        </Link>
      </figure>
      <h3 className={`${widgetIndex ? "mb-10" : ""}`}>
        <Link
          href={`/${item.link}`}
          onClick={() => {
            dispatch(setLoadingState(item.link !== "#"));
            dispatch(
              setOffCanvas({
                component: "",
                state: false,
              })
            );
          }}
        >
          {item.title}
        </Link>
      </h3>
      {hasDesc ? (
        <p className="font-xxs category-item-description">
          {strip_html_tags(item.desc)}
        </p>
      ) : null}
    </div>
  );
};

export { CategoryItem };
