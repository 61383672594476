"use client";

import { Button, CustomInput } from "@/components/core-components";
import {
  fetchProfileDetails,
  setAdddressDetail,
  setLoadingState,
  setQuickView,
  updateProfileDetails,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import {
  CountryCode,
  FORM_CONSTANTS,
  IProfileDetails,
  ProfileSchema,
  STATUSES,
} from "@/utils";
import { Form, Formik } from "formik";
import Link from "next/link";
import React, { useEffect } from "react";

const ProfileShimmer = () => {
  return (
    <div className="row">
      <div className="col-md-6">
        <span
          className="skeleton skeleton-text normal-shimmer"
          style={{ display: "block", width: "100%" }}
        ></span>
      </div>
      <div className="col-md-6">
        <span
          className="skeleton skeleton-text normal-shimmer"
          style={{ display: "block", width: "100%" }}
        ></span>
      </div>
      <div className="col-md-6">
        <span
          className="skeleton skeleton-text normal-shimmer"
          style={{ display: "block", width: "100%" }}
        ></span>
      </div>
      <div className="col-md-6">
        <span
          className="skeleton skeleton-text normal-shimmer"
          style={{ display: "block", width: "100%" }}
        ></span>
      </div>
    </div>
  );
};

const ProfileForm = ({ profileData }: { profileData: IProfileDetails }) => {
  const dispatch = useAppDispatch();

  const { profile_details } = useAppSelector((state) => state.auth);

  const { state, quickView } = useAppSelector((state) => state.common);

  useEffect(() => {
    dispatch(fetchProfileDetails({}));
    dispatch(setLoadingState(false));
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        first_name:
          profile_details?.first_name || profileData?.first_name || "",
        last_name: profile_details?.last_name || profileData?.last_name || "",
        contact_number:
          profile_details?.contact_number.toString() ||
          profileData?.contact_number.toString() ||
          "",
        email: profile_details?.email || profileData?.email || "",
        country: [
          {
            value:
              profile_details?.country.id || profileData?.country.id || 101,
            label: `${
              profile_details?.country.iso ||
              profile_details?.country.iso3 ||
              profileData?.country.iso ||
              profileData?.country.iso3 ||
              "IN"
            } +${
              profile_details?.country.phonecode ||
              profileData?.country.phonecode ||
              91
            }`,
            phonecode:
              profile_details?.country.phonecode ||
              profileData?.country.phonecode ||
              91,
            country:
              profile_details?.country.country ||
              profileData?.country.country ||
              "India",
          },
        ],
        gstin: profile_details?.gstin || profileData?.gstin || "",
        company_name:
          profile_details?.company_name || profileData?.company_name || "",
        is_business: Boolean(profile_details?.company_name),
      }}
      onSubmit={(values, actions) => {
        let businessData: any = {};
        if (
          profile_details &&
          profile_details.business_details &&
          Object.keys(profile_details.business_details)?.length > 0
        ) {
          businessData = {
            first_name: profile_details?.first_name || "",
            last_name: profile_details?.last_name || "",
            pincode: profile_details?.business_details?.pincode || "",
            address_line_1:
              profile_details?.business_details?.address_line_1 || "",
            address_line_2:
              profile_details?.business_details?.address_line_2 || "",
            landmark: profile_details?.business_details?.landmark || "",
            city: Number(profile_details?.business_details?.city?.id),
            state: Number(profile_details?.business_details?.state?.id),
            country: Number(profile_details?.business_details?.country?.id),
            address_type: 3,
            contact_number: Number(profile_details?.contact_number),
            country_code: profile_details?.country.id,
            set_as_default: profile_details?.business_details?.set_as_default
              ? 1
              : 0,
          };
        }

        dispatch(
          updateProfileDetails({
            data: {
              ...values,
              contact_number: Number(values?.contact_number),
              country: values?.country?.[0]?.value,
              business_details: businessData,
              gstin:
                businessData && Object.keys(businessData)?.length > 0
                  ? values?.gstin
                  : undefined,
              company_name:
                businessData && Object.keys(businessData)?.length > 0
                  ? values?.company_name
                  : undefined,
            },
          })
        );
      }}
      validationSchema={ProfileSchema}
    >
      {(profileProps) => (
        <Form>
          <div className="row">
            <div className="col-12 row">
              <div className="col-md-6">
                <CustomInput
                  placeholder="Enter First Name"
                  formikProps={profileProps}
                  label={"First Name"}
                  name={"first_name"}
                  isRequired={true}
                  type={FORM_CONSTANTS.TEXT}
                  titleCase={true}
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  placeholder="Enter Last Name"
                  formikProps={profileProps}
                  label={"Last Name"}
                  name={"last_name"}
                  isRequired={true}
                  type={FORM_CONSTANTS.TEXT}
                  titleCase={true}
                />
              </div>
            </div>
            <div className="col-12 row">
              <div className="col-md-6">
                <CustomInput
                  placeholder="Enter Email"
                  formikProps={profileProps}
                  label={"Email"}
                  disabled={true}
                  labelAddOn={
                    <Link
                      href="#"
                      onClick={() => {
                        dispatch(
                          setQuickView({
                            state: true,
                            type: "change-details",
                            data: {
                              type: "email",
                              value: profileProps?.values?.email,
                            },
                          })
                        );
                      }}
                    >
                      Change
                    </Link>
                  }
                  name={"email"}
                  isRequired={true}
                  type={FORM_CONSTANTS.EMAIL}
                />
              </div>
              <div className="col-md-6">
                <CustomInput
                  placeholder="Enter Mobile Number"
                  formikProps={profileProps}
                  countryCode
                  disabled={true}
                  labelAddOn={
                    <Link
                      href="#"
                      onClick={() => {
                        dispatch(
                          setQuickView({
                            state: true,
                            type: "change-details",
                            data: {
                              type: "contact_number",
                              value: profileProps?.values?.contact_number,
                            },
                          })
                        );
                      }}
                    >
                      Change
                    </Link>
                  }
                  label={"Mobile Number"}
                  name={"contact_number"}
                  isRequired={true}
                  type={FORM_CONSTANTS.NUMBER}
                  titleCase={true}
                />
              </div>
            </div>
            <div className="col-md-12 row mb-10">
              {profileProps?.values?.is_business ? (
                <>
                  <div className="col-md-6">
                    <CustomInput
                      placeholder="Enter Company Name"
                      label={"Company Name"}
                      name={"company_name"}
                      formikProps={profileProps}
                      type={FORM_CONSTANTS.TEXT}
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-6">
                    <CustomInput
                      placeholder="Enter GSTIN"
                      label={"GSTIN"}
                      name={"gstin"}
                      formikProps={profileProps}
                      type={FORM_CONSTANTS.TEXT}
                      disabled={true}
                      labelAddOn={
                        <Link
                          href="#"
                          onClick={() => {
                            dispatch(
                              setAdddressDetail(
                                profile_details?.business_details
                              )
                            );
                            dispatch(
                              setQuickView({
                                state: true,
                                data: null,
                                type: "business-details",
                              })
                            );
                          }}
                        >
                          Change
                        </Link>
                      }
                    />
                  </div>
                  <div className="col-12">
                    <label className="d-flex align-items-center justify-content-between gap-1">
                      <div className="d-flex gap-1">Business Address</div>
                    </label>
                    <p className="font-sm line-height-2 text-heading">{`${profile_details?.business_details?.address_line_1} ${profile_details?.business_details?.address_line_2} ${profile_details?.business_details?.landmark}`}</p>
                    <p className="font-sm line-height-2 text-heading">
                      {`${profile_details?.business_details?.city?.city}, ${profile_details?.business_details?.state?.state}, ${profile_details?.business_details?.pincode} , ${profile_details?.business_details?.country?.country}`}
                    </p>
                  </div>
                </>
              ) : (
                <Link
                  href="#"
                  onClick={() => {
                    dispatch(
                      setQuickView({
                        state: true,
                        data: null,
                        type: "business-details",
                      })
                    );
                  }}
                >
                  Add Business Details
                </Link>
              )}
            </div>
            {!quickView?.state && (
              <div className="col-md-12">
                <Button
                  type="submit"
                  className="btn btn-sm btn-fill-out submit font-weight-bold"
                  text={"Save Changes"}
                  loading={
                    state.status === STATUSES.LOADING &&
                    state.type === "update-profile"
                  }
                />
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { ProfileForm, ProfileShimmer };
