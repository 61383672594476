import React from "react";
import parse from "html-react-parser";
import Image from "next/image";
import { IBannerConfig, IBannerContent } from "@/utils";
import Link from "next/link";

import GiftHamper from "../Banners/GiftHamper";
import Banner from "../Banners/Banner";
// import BannerSlider from "../Banners/BannerSlider";
import BannersList from "../Banners/BannersList";
import BannerVideo from "../Banners/BannerVideo";
import CustomCarousel from "@/components/core-components/Carousel";

const BannerWidget = ({
  config,
  widgetIndex,
}: {
  config: IBannerConfig;
  widgetIndex: number;
}) => {
  const checkFileType = (url: string): "video" | "image" => {
    const ext = url.split(".").pop()?.toLowerCase();
    return ext === "mp4" || url.includes("youtube") ? "video" : "image";
  };

  const getCommonProps = (item: IBannerContent) => {
    const isImage = checkFileType(item.media) === "image";
    return {
      img: isImage ? item.media ?? "" : "",
      height: isImage ? item.img?.height ?? "" : "",
      width: isImage ? item.img?.width ?? "" : "",
      alt: item.alt,
      title: item.title,
      description: item.description,
      btnText: item.action?.text,
      link: item.action?.link,
      small: item?.small || false,
      className: item?.className || "",
      videoUrl: !isImage ? item.media : "",
    };
  };

  const renderBannerContent = (content: IBannerContent[]) =>
    content.map(getCommonProps);

  const renderSingleBanner = (banner: ReturnType<typeof getCommonProps>) => {
    const isVideo = checkFileType(banner.img || banner.videoUrl) === "video";

    if (isVideo) {
      // const BannerVideo = dynamic(() => import("../Banners/BannerVideo"));

      return (
        <section
          className="festive-option-3-section-1 section-padding-12"
          key={`video-${widgetIndex}`}
        >
          <BannerVideo data={banner} />
        </section>
      );
    }

    const bannerProps = {
      src: banner.img,
      alt: banner.alt,
      height: Number(banner.height),
      width: Number(banner.width),
    };

    if (config.flow === "horizontal") {
      if (banner.btnText) {
        // const GiftHamper = dynamic(() => import("../Banners/GiftHamper"));

        return (
          <section
            className="festive-option-3-section-2 section-padding-12"
            key={`banner-${widgetIndex}`}
          >
            <GiftHamper data={banner} widgetIndex={widgetIndex} />
          </section>
        );
      } else {
        if (config.display_width === "full-screen") {
          // const Banner = dynamic(() => import("../Banners/Banner"));

          return (
            <Banner
              {...bannerProps}
              widgetIndex={widgetIndex}
              key={`banner-full-screen-${widgetIndex}`}
            />
          );
        } else {
          return (
            <section
              className="bg-primary-2 section-padding-12"
              key={`banner-container-${widgetIndex}`}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <Image
                      alt={banner.alt}
                      src={banner.img}
                      width={1320}
                      height={445}
                      className={`w-100 h-100 border-radius-10`}
                      priority
                    />
                  </div>
                </div>
              </div>
            </section>
          );
        }
      }
    } else {
      // const Banner = dynamic(() => import("../Banners/Banner"));

      return (
        <section
          className="festive-option-3-section-3 section-padding-12"
          key={`banner-vertical-${widgetIndex}`}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="row mb-x">
                  <Banner
                    widgetIndex={widgetIndex}
                    className="col-lg-12 mb-30 d-flex flex-column-reverse"
                    {...bannerProps}
                  >
                    <div className="col-lg-12 text-center mt-40">
                      <h2 className="mb-30 prata-regular text-extra-dark">
                        {parse(banner.title)}
                      </h2>
                      {banner.btnText && (
                        <Link
                          href={banner.link}
                          className="btn btn-xl prata-regular"
                          // prefetch
                        >
                          {banner.btnText}
                        </Link>
                      )}
                    </div>
                  </Banner>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  };

  const renderBanner = () => {
    const data = renderBannerContent(config.content);

    if (config.content.length > 1) {
      // const CustomCarousel = dynamic(
      //   () => import("../../core-components/Carousel")
      // );

      if (config.type === "banner-carousel") {
        const sliderProps = {
          data,
          className: "hero-slider-1 style-4 dot-style-1 dot-style-1-position-1",
          btnClassName: "hero-slider-1-arrow",
        };

        return config.display_width === "full-screen" ? (
          <section
            className="home-slider position-relative"
            key={`banner-full-screen-carousel-${widgetIndex}`}
          >
            <div className="home-slider-cover"></div>
            <CustomCarousel
              items={data}
              prevBtn="custom_prev_i3"
              nextBtn="custom_next_i3"
            />
            {/* <BannerSlider
              lengthToRender={1}
              key={"full-screen-slider"}
              widgetIndex={widgetIndex}
              {...sliderProps}
              prevBtn="custom_prev_i3"
              nextBtn="custom_next_i3"
            /> */}
          </section>
        ) : (
          <div
            className="container section-padding-12"
            key={`banner-container-carousel-${widgetIndex}`}
          >
            <div className="row flex-row-reverse">
              <div className="col-lg-12">
                <section className="home-slider position-relative">
                  <div className="home-slide-cover">
                    <CustomCarousel
                      items={data}
                      prevBtn="custom_prev_i1"
                      nextBtn="custom_next_i1"
                    />
                    {/* <BannerSlider
                      lengthToRender={0}
                      key={"container-slider"}
                      widgetIndex={widgetIndex}
                      {...sliderProps}
                      prevBtn="custom_prev_i1"
                      nextBtn="custom_next_i1"
                      pagination={{ clickable: true }}
                    /> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        );
      } else {
        // const BannersList = dynamic(() => import("../Banners/BannersList"));

        return (
          <BannersList
            banners={data}
            direction={"horizontal"}
            widgetIndex={widgetIndex}
            key={`banner-grid-${widgetIndex}`}
          />
        );
      }
    }

    return renderSingleBanner(data[0]);
  };

  return renderBanner();
};

export { BannerWidget };
