"use client";

import { Loader, Table, TableBody } from "@/components/core-components";
import { useAppSelector } from "@/store";
import {
  calculateAllValues,
  displayValue,
  IDiscountData,
  to2Decimal,
} from "@/utils";
import React, { useMemo } from "react";
import { STATUSES } from "../../../utils/constants";

const CartSummary = ({
  title,
  discount = {
    discount_code: "",
    discount_value: 0,
    total_discount: 0,
    discount_type: "flat",
    productDiscount: [],
  },
  className,
  parentClassName,
  cartItems,
  shipping_cost,
  showShipping = true,
  showTotalItems = false,
}: {
  title?: string;
  discount?: IDiscountData;
  className?: string;
  parentClassName?: string;
  cartItems?: any;
  shipping_cost?: number;
  showShipping?: boolean;
  showTotalItems?: boolean;
}) => {
  const { state } = useAppSelector((state) => state.common);

  const { total_amount, sub_total, total_items, round_off } =
    calculateAllValues(cartItems);

  const { finalAmount, roundOff } = useMemo(() => {
    const payableAmount = to2Decimal(
      (total_amount || 0) +
        (shipping_cost || 0) -
        (discount.total_discount || 0)
    );

    const roundOff =
      Math.round(
        to2Decimal(
          (total_amount || 0) +
            (shipping_cost || 0) -
            (discount.total_discount || 0)
        )
      ) -
      to2Decimal(
        (total_amount || 0) +
          (shipping_cost || 0) -
          (discount.total_discount || 0)
      );
    return {
      finalAmount: payableAmount + roundOff,
      roundOff,
    };
  }, [shipping_cost, discount?.total_discount]);

  return (
    <div
      className={`${
        parentClassName
          ? parentClassName
          : "col-lg-12 col-md-12 order-summary-section"
      } mb-10`}
    >
      {title && (
        <div className="heading_s1 mb-3 d-flex align-items-center flex-row gap-2">
          <h5>{title}</h5>
          {showTotalItems && (
            <span>{`(${total_items} item${total_items > 1 ? "s" : ""})`}</span>
          )}
        </div>
      )}
      <Table tableClassName="mb-0">
        <TableBody>
          <tr>
            <td className="cart_total_label" width={"50%"}>
              Item(s) Subtotal
            </td>
            <td className="cart_total_amount text-end" width={"50%"}>
              <span className="font-sm fw-500 text-dark">
                <span className="rupee-symbol">₹</span>
                {displayValue(to2Decimal(sub_total || 0))}
              </span>
            </td>
          </tr>

          {discount.discount_value > 0 && (
            <tr>
              <td className="cart_total_label" width={"50%"}>
                <div className="d-flex flex-row gap-2">
                  <span>Discount</span>
                  <span className="text-brand">{`(${discount.discount_code})`}</span>
                </div>
              </td>
              <td className="cart_total_amount text-end" width={"50%"}>
                <span className="font-sm fw-500 text-dark">
                  <span className="rupee-symbol">₹</span>
                  {displayValue(to2Decimal(discount.total_discount))}
                </span>
              </td>
            </tr>
          )}

          {showShipping && (
            <tr>
              <td className="cart_total_label" width={"50%"}>
                Shipping
              </td>
              <td className="font-sm fw-500 text-end" width={"50%"}>
                {state.status === STATUSES.LOADING &&
                (state.type === "shipping-cost" ||
                  state.type === "fetch-addresses") ? (
                  <Loader />
                ) : (
                  <span className="font-sm fw-500 text-dark">
                    <span className="rupee-symbol">₹</span>
                    {displayValue(to2Decimal(shipping_cost || 0))}
                  </span>
                )}
              </td>
            </tr>
          )}

          {roundOff !== 0 && (
            <tr>
              <td className="cart_total_label" width={"50%"}>
                Round Off
              </td>
              <td className="cart_total_amount text-end" width={"50%"}>
                <strong>
                  <span className="font-sm fw-500 text-dark">
                    <span className="rupee-symbol">₹</span>
                    {displayValue(roundOff)}
                  </span>
                </strong>
              </td>
            </tr>
          )}

          <tr>
            <td className="cart_total_label" width={"50%"}>{`${
              title?.includes("Order") ? "Order" : ""
            } Total`}</td>
            <td className="cart_total_amount text-end" width={"50%"}>
              <strong>
                <span className="font-sm fw-500 text-dark">
                  <span className="rupee-symbol">₹</span>
                  {displayValue(showShipping ? finalAmount : total_amount)}
                </span>
              </strong>
            </td>
          </tr>
        </TableBody>
      </Table>
    </div>
  );
};

export { CartSummary };
