"use client";

import { Button, StarRating, TextArea } from "@/components/core-components";
import { addReview, useAppDispatch, useAppSelector } from "@/store";
import { ReviewSchema, STATUSES } from "@/utils";
import { ErrorMessage, Form, Formik } from "formik";

const ReviewForm = ({
  modalData = { state: false, data: null },
}: {
  modalData: { state: boolean; data: any };
}) => {
  const dispatch = useAppDispatch();

  const { state } = useAppSelector((state) => state.common);

  const handleSubmit = (values: any, actions: any) => {
    const reviewToAdd = {
      product_id: modalData.data.product_id,
      rating: values.rating,
      review_comment: values.review_comment,
      review_status: "pending",
      review_media: [],
    };
    dispatch(
      addReview({
        params: {
          data: reviewToAdd,
          product: "",
        },
        actions: actions,
      })
    );
  };

  return (
    <div className="section-padding-12 pt-25 pb-25">
      <div className="container">
        <div className="row">
          <h4 className="mb-15">Add a review</h4>
          <div className="col-12">
            <Formik
              initialValues={{
                rating: 0,
                review_comment: "",
                review_status: "pending",
                review_media: [],
              }}
              validationSchema={ReviewSchema}
              onSubmit={handleSubmit}
            >
              {(reviewProps) => (
                <Form className="form-contact comment_form ">
                  <div className="product-rating mb-30">
                    <StarRating
                      totalStars={5}
                      setFieldValue={reviewProps.setFieldValue}
                      value={reviewProps.values.rating}
                    />
                    <ErrorMessage
                      name="rating"
                      className="text-danger mt-2"
                      component={"div"}
                    />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <TextArea
                        label=""
                        name="review_comment"
                        rows={10}
                        cols={5}
                        placeholder="Write Review"
                        formikProps={reviewProps}
                      />
                    </div>
                  </div>
                  <Button
                    className="btn btn-sm button-contactForm"
                    loading={
                      state.status === STATUSES.LOADING &&
                      state.type === "add-review"
                    }
                    text="Submit Review"
                    type="submit"
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ReviewForm };
