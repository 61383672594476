import { to2Decimal } from "./helper";
import {
  GTagItemsSummary,
  EventPayload,
  GtagEventName,
  IOrderDetail,
} from "./types";

export const gtagEvent = <E extends GtagEventName>(
  action: E,
  payload: EventPayload<E>
) => {
  if (typeof window !== "undefined") {
    window?.gtag && window?.gtag("event", action, payload);
  }
};

export const generateGtagEventPayload = ({
  product,
  currentPrice,
  quantity = 1,
  type,
  index,
  weight,
}: {
  product: {
    product_code: string;
    title: string;
    category: string;
    sub_category: string;
  };
  currentPrice: number;
  type: string;
  quantity: number;
  index?: number;
  weight?:
    | {
        id: number;
        value: number;
        label: string;
        uom: string;
      }
    | undefined;
}): GTagItemsSummary => {
  const eventData = {
    currency: "INR",
    value: to2Decimal(currentPrice * quantity),
    items: [
      {
        item_id: product.product_code,
        item_list_id: type,
        item_name: product.title,
        item_brand: "Avarya",
        item_category: product.category,
        item_category2: product.sub_category,
        quantity: quantity,
        price: to2Decimal(currentPrice / quantity),
        ...(weight !== undefined && {
          weight: weight?.value,
          uom: weight?.uom,
        }),
        ...(index !== undefined && { index }),
      },
    ],
  };

  return eventData;
};

export const trackAddToCartWishlist = (
  event:
    | "view_item"
    | "view_cart"
    | "add_to_cart"
    | "remove_from_cart"
    | "add_to_wishlist"
    | "begin_checkout",
  payload: GTagItemsSummary
) => {
  gtagEvent(event, payload);
};

export const trackLogin = (payload: EventPayload<"login">) => {
  gtagEvent("login", payload);
};

export const trackViewItemList = (payload: EventPayload<"view_item_list">) => {
  gtagEvent("view_item_list", payload);
};

export const trackSearch = (payload: EventPayload<"search">) => {
  gtagEvent("search", payload);
};

export const trackSearchResults = (
  payload: EventPayload<"view_search_results">
) => {
  gtagEvent("view_search_results", payload);
};

export const trackPurchase = (orderDetails: IOrderDetail) => {
  const ShippingAddress = orderDetails.addresses?.find(
    (address) => address.address_type === "shipping"
  );

  const BillingAddress = orderDetails.addresses?.find(
    (address) => address.address_type === "billing"
  );

  const eventData: EventPayload<"purchase"> = {
    currency: "INR",
    transaction_id: (
      orderDetails.payment_data.find(
        (item) => item.payment_status === "success"
      )?.payment_gateway_id || ""
    ).toString(),
    value: orderDetails.payable_amount,
    shipping: orderDetails.total_shipping,
    items: orderDetails.ordered_products.map((orderItem) => ({
      item_id: orderItem.product.product_code,
      item_list_id: "purchase",
      item_name: orderItem.product.title,
      item_brand: "Avarya",
      item_category: orderItem.product.category,
      item_category2: orderItem.product.sub_category,
      quantity: orderItem.quantity,
      price: to2Decimal(orderItem.payable_amount / orderItem.quantity),
      weight: orderItem.product.selected_weight,
      uom: orderItem.product?.uom,
    })),
    shipping_address: ShippingAddress
      ? `${ShippingAddress.address_line_1}, ${ShippingAddress.address_line_2}, ${ShippingAddress.city.city}, ${ShippingAddress.state.state}, ${ShippingAddress.country.country}, ${ShippingAddress.postal_code}`
      : "",
    billing_address: BillingAddress
      ? `${BillingAddress.address_line_1}, ${BillingAddress.address_line_2}, ${BillingAddress.city.city}, ${BillingAddress.state.state}, ${BillingAddress.country.country}, ${BillingAddress.postal_code}`
      : "",
  };

  gtagEvent("purchase", eventData);
};
