import React from "react";

const CheckoutShimmer = () => {
  return (
    <div className="container py-4">
      {/* Checkout title shimmer */}
      <div className="row">
        <div className="col-lg-8 mb-15">
          <div className="section-title style-2 mb-0">
            <h3>Checkout</h3>
          </div>
        </div>
      </div>

      <div className="row">
        {/* Left side - Shipping Address */}
        <div className="col-md-8">
          <div className="mb-4">
            <span
              className="skeleton skeleton-text normal-shimmer mb-3"
              style={{ width: "180px", height: "24px" }}
            ></span>

            {/* Address Cards */}
            {[1, 2, 3].map((item) => (
              <div key={item} className="border p-4 mb-3">
                <div className="d-flex justify-content-between align-items-start">
                  <div className="flex-grow-1">
                    <span
                      className="skeleton skeleton-text normal-shimmer d-block mb-2"
                      style={{ width: "150px", height: "20px" }}
                    ></span>
                    <span
                      className="skeleton skeleton-text normal-shimmer d-block mb-2"
                      style={{ width: "200px", height: "16px" }}
                    ></span>
                    <span
                      className="skeleton skeleton-text normal-shimmer d-block"
                      style={{ width: "180px", height: "16px" }}
                    ></span>
                  </div>
                  <span
                    className="skeleton skeleton-text normal-shimmer"
                    style={{ width: "80px", height: "32px" }}
                  ></span>
                </div>
              </div>
            ))}

            {/* Add New Address button shimmer */}
            {/* <span
              className="skeleton skeleton-text normal-shimmer"
              style={{ width: "150px", height: "40px" }}
            ></span> */}
          </div>
        </div>

        {/* Right side - Order Summary */}
        <div className="col-md-4">
          {/* Coupon Section */}
          {/* <div className="mb-4">
            <span
              className="skeleton skeleton-text normal-shimmer mb-3"
              style={{ width: "120px", height: "24px" }}
            ></span>
            <div className="d-flex gap-2">
              <span
                className="skeleton skeleton-text normal-shimmer flex-grow-1"
                style={{ height: "40px" }}
              ></span>
              <span
                className="skeleton skeleton-text normal-shimmer"
                style={{ width: "80px", height: "40px" }}
              ></span>
            </div>
          </div> */}

          {/* Order Summary Section */}
          <div className="border p-4">
            <span
              className="skeleton skeleton-text normal-shimmer mb-4"
              style={{ width: "140px", height: "24px" }}
            ></span>

            {/* Order Items */}
            {[1, 2, 3].map((item) => (
              <div key={item} className="d-flex gap-3 mb-4">
                <span
                  className="skeleton skeleton-text normal-shimmer"
                  style={{ width: "80px", height: "80px" }}
                ></span>
                <div className="flex-grow-1">
                  <span
                    className="skeleton skeleton-text normal-shimmer d-block mb-2"
                    style={{ width: "140px", height: "20px" }}
                  ></span>
                  <span
                    className="skeleton skeleton-text normal-shimmer d-block mb-2"
                    style={{ width: "80px", height: "16px" }}
                  ></span>
                  <span
                    className="skeleton skeleton-text normal-shimmer d-block"
                    style={{ width: "100px", height: "16px" }}
                  ></span>
                </div>
                <span
                  className="skeleton skeleton-text normal-shimmer"
                  style={{ width: "80px", height: "20px" }}
                ></span>
              </div>
            ))}

            {/* Price Summary */}
            <div className="mt-4">
              <div className="d-flex justify-content-between mb-2">
                <span
                  className="skeleton skeleton-text normal-shimmer"
                  style={{ width: "100px", height: "16px" }}
                ></span>
                <span
                  className="skeleton skeleton-text normal-shimmer"
                  style={{ width: "80px", height: "16px" }}
                ></span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span
                  className="skeleton skeleton-text normal-shimmer"
                  style={{ width: "80px", height: "16px" }}
                ></span>
                <span
                  className="skeleton skeleton-text normal-shimmer"
                  style={{ width: "80px", height: "16px" }}
                ></span>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <span
                  className="skeleton skeleton-text normal-shimmer"
                  style={{ width: "100px", height: "20px" }}
                ></span>
                <span
                  className="skeleton skeleton-text normal-shimmer"
                  style={{ width: "100px", height: "20px" }}
                ></span>
              </div>
            </div>

            {/* Place Order Button */}
            <span
              className="skeleton skeleton-text gradient-shimmer d-block w-100 mt-4"
              style={{ height: "48px" }}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CheckoutShimmer };
