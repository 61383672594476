"use client";

import React from "react";
import parse from "html-react-parser";

const Tags = ({ product }: { product: any }) => {
  return (
    <div className="d-flex mb-10 align-items-center">
      {product.discount.value > 0 &&
        parse(
          '<span className="stock-status out-stock bg-orange text-white mb-0 mr-10">Sale Off </span>'
        )}
      {product.jain_friendly &&
        parse(
          '<span className="stock-status out-stock bg-green text-white mb-0 mr-10">Jain </span>'
        )}
      <div className="veg-container d-flex flex-row align-items-center">
        <div className="veg-inner-container veg-inner-container-veg">
          <div className="veg-outer">
            <div className="veg-circle veg-circle-veg"></div>
          </div>
        </div>
        <div className="veg-text">100% VEG.</div>
      </div>
    </div>
  );
};

export { Tags };
