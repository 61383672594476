import Image from "next/image";
import Link from "next/link";
import React from "react";

import greyedStar from "@media/icons/icon-star-grey.svg";
import filledStar from "@media/icons/icon-star.svg";
import halfFilledStar from "@media/icons/icon-star-half-filled.svg";

const Ratings = ({ rating }: { rating: number }) => {
  const renderRating = (rating: number) => {
    const stars = [];

    for (let i = 1; i <= 5; i++) {
      if (rating >= i) {
        // Full star
        stars.push(
          <Image
            width={16}
            height={16}
            key={i}
            src={filledStar}
            alt={`rating-${i}`}
            className="img-fluid w-100 h-100"
          />
        );
      } else if (rating >= i - 0.5) {
        // Half star
        stars.push(
          <Image
            width={16}
            height={16}
            key={i}
            src={halfFilledStar}
            alt={`rating-${i}-half`}
            className="img-fluid w-100 h-100"
          />
        );
      } else {
        // Empty star
        stars.push(
          <Image
            width={16}
            height={16}
            key={i}
            src={greyedStar}
            alt={`rating-${i}`}
            className="img-fluid w-100 h-100"
          />
        );
      }
    }

    return stars;
  };

  return (
    <Link
      href={`https://www.google.com/search?q=avarya&rlz=1C1CHBF_enIN839IN839&tbm=lcl&ei=YAV0YK3gJczaz7sP5b-K4AY&gs_l=psy-ab.1.0.0i273k1j0i433k1j0j0i67k1j0j0i67k1j0l2j0i67k1l2.56301.56301.0.57756.1.1.0.0.0.0.136.136.0j1.1.0....0...1c.2.64.psy-ab..0.1.136....0.n02pwixf-r4#lrd=0x3be7c9f604479421:0x7a34b08bed053e2e,1,,,&rlfi=hd:;si:8805857286439648814,l,CgZhdmFyeWFIueX_7ouwgIAIWgwQABgAIgZhdmFyeWGSAQtjYW5keV9zdG9yZZoBI0NoWkRTVWhOTUc5blMwVkpRMEZuU1VSYVgzSlFZa05SRUFFqgEvEAEyHxABIhtEDHA-nwipNmGrlMdjgzFTnJyaUpK9v3ib5gkyChACIgZhdmFyeWE,y,0J_nTLajexE;mv:[[19.2142385,73.0439797],[18.932568099999997,72.7829876]]`}
      target="_blank"
    >
      <div className="product-rating">{renderRating(rating)}</div>
    </Link>
  );
};

export { Ratings };
