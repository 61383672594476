"use client";

import {
  capitalizeFirstLetter,
  STATIC_CONSTANTS,
  trackSearchResults,
} from "@/utils";
import { ProductListing } from "./Product/ProductListing";
import { Pagination } from "@/components/Pagination";
import { ExtraFiltersForm } from "./ExtraFiltersForm";
import { Button } from "@/components/core-components";
import {
  fetchFilteredProducts,
  setFilters,
  setInitialListingData,
  setLoadingState,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import React, { useEffect, useMemo } from "react";
import { useSearchParams } from "next/navigation";

const ListingWithFilters = ({
  data,
  type = "",
  search = false,
  total_pages = 1,
  count,
  title,
  filters = [],
  widgetIndex = 0,
  extraFilters = false,
  clearBtnText,
  clearBtnClick,
  individualDelete,
  largeCard = false,
  hasDesc = false,
  fetchUrl,
  serverFilters = false,
  last = false,
  taste = [],
}: {
  data?: any[];
  taste?: any[];
  type: string;
  fetchUrl?: string;
  total_pages?: number;
  count?: number;
  search?: boolean;
  title: string;
  filters: string[];
  largeCard?: boolean;
  widgetIndex?: number;
  extraFilters?: boolean;
  hasDesc?: boolean;
  clearBtnText?: string;
  clearBtnClick?: () => void;
  individualDelete?: (value: any) => void;
  serverFilters?: boolean;
  last?: boolean;
  showLimit?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const searchParams = useSearchParams();

  const { filter, filteredProducts, totalPages } = useAppSelector(
    (state) => state.common
  );

  const noFiltersApplied =
    filter.page === 1 &&
    filter.is_jain === 0 &&
    filter.query === "" &&
    filter.taste === "" &&
    filter.sort_by === "all";

  const renderFilters = useMemo(() => {
    return (
      <div className="shop-product-fillter">
        <div className="totall-product"></div>
        <ExtraFiltersForm
          widgetIndex={widgetIndex}
          taste={taste}
          showLimit={
            type !== "search" &&
            STATIC_CONSTANTS.LISTING_EXCLUDE.includes(type || "")
          }
          search={search}
          serverFilters={serverFilters}
          clearBtnClick={clearBtnClick}
          clearBtnText={clearBtnText}
        />
      </div>
    );
  }, [
    taste,
    search,
    serverFilters,
    clearBtnClick,
    clearBtnText,
    type,
    data,
    widgetIndex,
  ]);

  const renderPagination = useMemo(() => {
    return (
      (filteredProducts?.[widgetIndex]?.length > 0 ||
        (data && data?.length > 0)) &&
      (total_pages > 1 || totalPages?.[widgetIndex] > 1) && (
        <div className="pagination-area mt-5 mb-sm-5 mb-lg-0 mb-0">
          <nav aria-label="Page navigation example">
            <Pagination
              total_page={
                noFiltersApplied && data && data?.length > 0
                  ? total_pages
                  : totalPages?.[widgetIndex]
              }
              serverFilters={serverFilters}
              widgetIndex={widgetIndex}
            />
          </nav>
        </div>
      )
    );
  }, [widgetIndex, total_pages, totalPages[widgetIndex]]);

  useEffect(() => {
    if (
      !serverFilters &&
      (!noFiltersApplied || filter.page > 1) &&
      filter.widgetIndex === widgetIndex
    ) {
      dispatch(
        fetchFilteredProducts({
          filters: {
            ...filter,
            limit: STATIC_CONSTANTS.LISTING_EXCLUDE.includes(type || "")
              ? 24
              : filter.limit,
          },
          url: fetchUrl || "/shop/product/search/",
          search: serverFilters,
          widgetIndex: widgetIndex,
        })
      );
    }
  }, [
    filter.is_jain,
    filter.limit,
    filter.page,
    filter.query,
    filter.taste,
    filter.sort_by,
    fetchUrl,
  ]);

  useEffect(() => {
    dispatch(setLoadingState(false));

    if (type === "search") {
      trackSearchResults({ search_term: searchParams.get("query") || "" });
    }
    dispatch(
      setInitialListingData({
        data: data,
        widgetIndex: widgetIndex,
        total_pages: total_pages,
      })
    );

    return () => {
      dispatch(setLoadingState(false));
    };
  }, [dispatch]);

  return (
    <section className="product-tabs section-border-bottom section-padding-12 position-relative">
      <div className="container" id={`listing-container-${widgetIndex}`}>
        <div className="row">
          {extraFilters && (
            <React.Fragment>
              <div
                className={`${
                  STATIC_CONSTANTS.LISTING_EXCLUDE.includes(type || "")
                    ? "col-md-4 col-12"
                    : "col-12"
                }`}
              >
                <div className="section-title">
                  <h2>{title}</h2>
                </div>
              </div>
              {STATIC_CONSTANTS.LISTING_EXCLUDE.includes(type || "") && (
                <div className="col-8">{renderFilters}</div>
              )}
            </React.Fragment>
          )}
          <div className="col-12">
            {!STATIC_CONSTANTS.LISTING_EXCLUDE.includes(type || "") &&
              (extraFilters ? (
                renderFilters
              ) : (
                <div className="section-title d-flex flex-row align-items-center style-2 wow animate__animated animate__fadeIn">
                  <h2>{title}</h2>
                  {filters.length > 0 && (
                    <ul className="nav nav-tabs links filter-right">
                      {filters.map((item, index) => (
                        <li className="nav-item" key={index}>
                          <Button
                            className={
                              capitalizeFirstLetter(filter.sort_by || "") ===
                              item
                                ? "nav-link active"
                                : "nav-link"
                            }
                            onClick={() => {
                              dispatch(
                                setFilters({
                                  ...filter,
                                  sort_by: item === "All" ? "" : item,
                                })
                              );
                            }}
                            text={item}
                            type="button"
                          />
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            <div className="tab-content wow fadeIn animated">
              <div className="tab-pane fade show active">
                <div
                  className={`row product-grid-${individualDelete ? "3" : "4"}`}
                >
                  <ProductListing
                    type={type}
                    title={title}
                    widgetIndex={widgetIndex || 1}
                    hasDesc={hasDesc}
                    products={
                      noFiltersApplied && data && data?.length > 0
                        ? data
                        : filteredProducts?.[widgetIndex]
                    }
                    deleteBtn={individualDelete}
                    largeCard={largeCard}
                  />
                </div>
              </div>
            </div>
            {renderPagination}
            {!last && <div className={`section-border-top`} />}
          </div>
        </div>
      </div>
    </section>
  );
};

export { ListingWithFilters };
