import React from "react";

const Loader = ({ size = "sm" }: { size?: "xs" | "sm" | "md" | "lg" }) => {
  return (
    <span
      data-testid="loader"
      className={`spinner-border spinner-border-${size} mr-2`}
    ></span>
  );
};

export { Loader };
