"use client";

import { Button, Loader, Ratings } from "@/components/core-components";
import {
  removeCartItem,
  removeProduct,
  updateCartItem,
  updateQuantity,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import {
  BUTTON_CONSTANTS,
  displayValue,
  STATUSES,
  to2Decimal,
  trackAddToCartWishlist,
} from "@/utils";
import Image from "next/image";
import Link from "next/link";
import React from "react";

const CartItem = ({ index }: { index: number }) => {
  const dispatch = useAppDispatch();

  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const { state } = useAppSelector((state) => state.common);

  const { appliedDiscount, cartItems } = useAppSelector((state) => state.cart);

  const is_logged_in = logged_in_user || access_token;

  function trackRemoveCartItem() {
    const eventdata = {
      currency: "INR",
      value: to2Decimal(cartItems[index].payable_amount),
      items: [
        {
          item_id: cartItems[index].product_code,
          item_list_id: "cart",
          item_name: cartItems[index].title,
          item_brand: "Avarya",
          item_category: cartItems[index].category,
          item_category2: cartItems[index].sub_category,
          quantity: cartItems[index].quantity,
          price: cartItems[index].price,
        },
      ],
    };

    trackAddToCartWishlist("remove_from_cart", eventdata);
  }

  return (
    <tr>
      <td className="image product-thumbnail">
        <Image
          height={120}
          width={120}
          alt={cartItems[index]?.thumbnails?.[0]?.alt}
          src={cartItems[index]?.thumbnails?.[0]?.img}
          className="img-fluid"
        />
      </td>

      <td className="product-des product-name v-align-top">
        <div className="d-flex flex-column">
          <h6 className="product-name">
            <Link href={cartItems[index].slug}>{cartItems[index].title}</Link>
          </h6>
          <div className="product-rate-cover pb-1">
            <Ratings rating={cartItems[index].rating} />
          </div>
          <span className="text-danger underline">
            <Link
              href={"#"}
              onClick={() => {
                if (is_logged_in) {
                  dispatch(
                    removeCartItem({ cart_id: cartItems[index].cart_id })
                  );
                  trackRemoveCartItem();
                } else {
                  dispatch(removeProduct({ index: index, local: true }));
                }
              }}
            >
              {is_logged_in &&
              state.status === STATUSES.LOADING &&
              state.type === `delete-cartItem-${cartItems[index].cart_id}` ? (
                <Loader />
              ) : (
                "Delete"
              )}
            </Link>
          </span>
        </div>
      </td>

      <td className="text-center v-align-top detail-info pl-10 pr-10">
        <h6 className="text-brand">{`${cartItems[index].weight}${cartItems[index].uom}`}</h6>
      </td>

      <td className="price text-center v-align-top">
        <h6 className="text-brand">
          <span className="rupee-symbol">₹</span>
          {displayValue(cartItems[index].price)}
        </h6>
      </td>

      <td className="text-center detail-info v-align-top">
        <div className="d-flex flex-row justify-content-center detail-extralink">
          <div className="quantity-selector">
            <span className="qty-val">{cartItems[index].quantity}</span>
            <div className="quantity-carets">
              <Button
                type={BUTTON_CONSTANTS.BUTTON}
                text={<i className="fi-rs-angle-small-up"></i>}
                className="qty-up text-brand border-none cartitem-qty-selector"
                onClick={() => {
                  if (is_logged_in) {
                    dispatch(
                      updateCartItem({
                        cart_id: cartItems[index].cart_id,
                        quantity: cartItems[index].quantity + 1,
                      })
                    );
                  } else {
                    dispatch(
                      updateQuantity({
                        index: index,
                        quantity: cartItems[index].quantity + 1,
                        local: true,
                      })
                    );
                  }
                }}
              />

              <Button
                type={BUTTON_CONSTANTS.BUTTON}
                text={<i className="fi-rs-angle-small-down"></i>}
                className="qty-down text-brand border-none cartitem-qty-selector"
                onClick={() => {
                  if (is_logged_in) {
                    dispatch(
                      updateCartItem({
                        cart_id: cartItems[index].cart_id,
                        quantity: cartItems[index].quantity - 1,
                      })
                    );
                  } else {
                    dispatch(
                      updateQuantity({
                        index: index,
                        quantity: cartItems[index].quantity - 1,
                        local: true,
                      })
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
      </td>

      {appliedDiscount.discount_value > 0 ? (
        <td className="price text-center v-align-top">
          <h6 className="text-brand">
            <span className="rupee-symbol">₹</span>
            {displayValue(cartItems[index].discount_value)}
          </h6>
        </td>
      ) : null}

      <td className="text-center v-align-top">
        <h6 className="fw-600 font-18">
          <span className="rupee-symbol">₹</span>
          {displayValue(cartItems[index].payable_amount)}
        </h6>
      </td>
    </tr>
  );
};

export { CartItem };
