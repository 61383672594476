"use client";

import React from "react";
import { Toast, TOAST_CONSTANTS } from "@/utils";

const FileUpload = ({
  //   name,
  //   label,
  //   value,
  //   multiple = true,
  //   editImage,
  name,
  value,
  onChange,
  setFieldValue,
  deleteImage,
  max_files = 5,
  max_size = 5,
  action,
  inputRef,
  ...props
}: {
  name: string;
  label?: string;
  value?: any[];
  multiple?: boolean;
  onChange?: (files: any) => void;
  editImage?: () => void;
  deleteImage?: (value: any) => void;
  setFieldValue?: any;
  max_files?: number;
  max_size?: number;
  action?: (file: File) => void;
  inputRef?: any;
}) => {
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const files = Array.from(e.target.files);
    const maxSize = max_size * 1024 * 1024; // 5MB in bytes
    const maxFiles = max_files;
    const validExtensions = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/webp",
      "application/pdf",
    ];

    // Check if any file exceeds the maximum size
    if (files.some((file) => file.size > maxSize)) {
      Toast({
        message: "File exceeds maximum size, Please add files under 5MB",
        type: TOAST_CONSTANTS.WARNING,
      });
      return;
    }

    // Check if the number of files exceeds the limit
    if (files.length > maxFiles) {
      Toast({ message: "Files exceeded limit", type: TOAST_CONSTANTS.WARNING });
      return;
    }

    // Check if all files have valid extensions
    if (!files.every((file) => validExtensions.includes(file.type))) {
      Toast({ message: "Invalid File Type", type: TOAST_CONSTANTS.WARNING });
      return;
    }

    const filesToAdd: string[] = [];

    if (action) {
      // Process valid files
      files.forEach((file) => {
        action(file);
        // dispatch(
        //   uploadImage({
        //     params: {
        //       folder_name: `product/review`,
        //       file_name: file.name,
        //       file: file,
        //     },
        //     updatePropsValues: (value) => {
        //       filesToAdd.push(value);
        //     },
        //   })
        // );
      });
    }

    onChange && onChange(files);
  };

  // const previewContent = useMemo(() => {
  //   return (
  //     <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
  //       {value &&
  //         value?.map((media, index) => (
  //           <div key={index} style={{ margin: "10px", position: "relative" }}>
  //             <Image
  //               src={URL.createObjectURL(media)}
  //               alt={media.name}
  //               className="upload-media-img"
  //               width={100}
  //               height={100}
  //             />
  //             <Button
  //               className="upload-media-remove-btn py-2 px-0"
  //               loading={false}
  //               type="button"
  //               onClick={() => {
  //                 deleteImage && deleteImage(media);
  //               }}
  //               text="&times;"
  //             />
  //           </div>
  //         ))}
  //     </div>
  //   );
  // }, [value?.length]);

  return (
    <div>
      <input
        ref={inputRef}
        multiple
        type="file"
        accept="image/png, image/jpeg, image/jpg, image/png, image/webp, application/pdf"
        onChange={handleFileUpload}
        {...props}
      />
      {/* {previewContent} */}
    </div>
  );
};

export { FileUpload };
