"use client";

import { setQuickView, useAppDispatch } from "@/store";
import React from "react";

const ReviewModal = ({
  modalData = { state: false, data: null },
}: {
  modalData: { state: boolean; data: any };
}) => {
  if (!modalData.state) return null;

  const dispatch = useAppDispatch();

  const handleCloseClick = (e: any) => {
    e.stopPropagation();
    dispatch(setQuickView({ state: false, type: "", data: null }));
  };

  return (
    <div className="review-media-modal modalOverlay" onClick={handleCloseClick}>
      <div className="modalContent" onClick={(e) => e.stopPropagation()}>
        <button className="closeButton" onClick={handleCloseClick}>
          &times;
        </button>
        {modalData.data.content.media_type === "image" && (
          <img
            src={modalData.data.content.media_url}
            alt="Selected"
            className="modal-image"
          />
        )}
        {modalData.data.content.media_type === "video" && (
          <video controls className="modal-video">
            <source src={modalData.data.content.media_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    </div>
  );
};

export { ReviewModal };
