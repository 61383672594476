"use client";

import {
  setFilters,
  setMaxPageLimitState,
  setMinPageLimitState,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { BUTTON_CONSTANTS, GENERAL_CONSTANTS, usePagination } from "@/utils";
import React from "react";
import { Button } from "./core-components";

const Pagination = ({
  total_page,
  serverFilters = false,
  widgetIndex,
}: {
  total_page: number;
  serverFilters?: boolean;
  widgetIndex: number;
}) => {
  const pages: number[] = Array.apply(null, {
    length: total_page,
  } as number[]).map(Number.call, Number) as number[];

  const dispatch = useAppDispatch();

  const { filter } = useAppSelector((state) => state.common);
  const { updatePage, currentPage, onPreviousClick, onNextClick } =
    usePagination();

  const { maxPageLimit, minPageLimit } = useAppSelector(
    (state) => state.common
  );

  const scrollToTop = () => {
    document
      .getElementById(`listing-container-${widgetIndex}`)
      ?.scrollIntoView({
        behavior: "smooth",
      });
  };

  return total_page > 1 ? (
    <React.Fragment>
      <ul className="pagination justify-content-end">
        {(serverFilters ? currentPage - 1 : filter.page - 1) === 0 ? null : (
          <li className="page-item">
            <Button
              className="border-none"
              onClick={() => {
                if (serverFilters) {
                  onPreviousClick(currentPage - 1);
                } else {
                  if (
                    (filter.page - 1) % GENERAL_CONSTANTS.PER_PAGE_LIMIT ===
                    0
                  ) {
                    dispatch(
                      setMaxPageLimitState(
                        maxPageLimit - GENERAL_CONSTANTS.PER_PAGE_LIMIT
                      )
                    );
                    dispatch(
                      setMinPageLimitState(
                        minPageLimit - GENERAL_CONSTANTS.PER_PAGE_LIMIT
                      )
                    );
                  }
                  dispatch(
                    setFilters({
                      ...filter,
                      widgetIndex: widgetIndex,
                      page: filter.page - 1,
                    })
                  );
                }
                scrollToTop();
              }}
              text={<i className="fi-rs-angle-double-small-left"></i>}
              type={BUTTON_CONSTANTS.BUTTON}
              aria-label="prev"
            />
          </li>
        )}

        {pages.map((item, index) => {
          if (item + 1 <= maxPageLimit && item + 1 >= minPageLimit) {
            return (
              <li
                className={
                  (serverFilters ? currentPage : filter.page) === +item + 1
                    ? "page-item active"
                    : "page-item"
                }
              >
                <Button
                  aria-label={`page-${+item + 1}`}
                  text={+item + 1}
                  type={BUTTON_CONSTANTS.BUTTON}
                  className="border-none page-link"
                  onClick={() => {
                    if (serverFilters) {
                      updatePage(+item + 1);
                    } else {
                      dispatch(
                        setFilters({
                          ...filter,
                          widgetIndex: widgetIndex,
                          page: +item + 1,
                        })
                      );
                    }
                    scrollToTop();
                  }}
                  key={`pagination-item-${index}`}
                />
              </li>
            );
          }
        })}

        {(serverFilters ? currentPage : filter.page) === +total_page ? null : (
          <li className="page-item">
            <Button
              text={<i className="fi-rs-angle-double-small-right"></i>}
              type={BUTTON_CONSTANTS.BUTTON}
              className="page-link border-none"
              aria-label="next"
              onClick={() => {
                if (serverFilters) {
                  onNextClick(currentPage + 1);
                } else {
                  if (filter.page >= maxPageLimit) {
                    dispatch(
                      setMaxPageLimitState(
                        maxPageLimit + GENERAL_CONSTANTS.PER_PAGE_LIMIT
                      )
                    );
                    dispatch(
                      setMinPageLimitState(
                        minPageLimit + GENERAL_CONSTANTS.PER_PAGE_LIMIT
                      )
                    );
                  }
                  dispatch(
                    setFilters({
                      ...filter,
                      widgetIndex: widgetIndex,
                      page: filter.page + 1,
                    })
                  );
                }
                scrollToTop();
              }}
            />
          </li>
        )}
      </ul>

      {/* {total_page <= 0 ? null : (
        <p className="text-end ml-0">
          Show {serverFilters ? currentPage : filter.page} of {pages.length}
        </p>
      )} */}
    </React.Fragment>
  ) : null;
};

export { Pagination };
