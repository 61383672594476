"use client";

import {
  Loader,
  Table,
  TableBody,
  TableHeader,
} from "@/components/core-components";
import { setWholeCart, useAppDispatch, useAppSelector } from "@/store";
import React, { useEffect, useMemo } from "react";
import { CartItem } from "./CartItem";
import Link from "next/link";
import { CartSummary } from "./CartSummary";
import {
  API_ROUTES,
  calculateAllValues,
  canPlaceOrder,
  CART_CONSTANTS,
  formatCartItems,
  ICartItem,
  STATUSES,
  to2Decimal,
  trackAddToCartWishlist,
} from "@/utils";
import { useRouter } from "next/navigation";
import { GlobalEmpty } from "@/components/GlobalEmpty";
import { GlobalError } from "@/components/GlobalError";

const Cart = () => {
  const dispatch = useAppDispatch();

  const router = useRouter();

  const { logged_in_user, access_token } = useAppSelector(
    (state) => state.auth
  );

  const { state } = useAppSelector((state) => state.common);

  const { cartItems } = useAppSelector((state) => state.cart);

  const is_logged_in = Boolean(logged_in_user || access_token);

  const canProceedToCheckout = useMemo(
    () => canPlaceOrder(cartItems),
    [cartItems?.length]
  );

  function trackViewCart(cartItems: ICartItem[]) {
    const { total_amount } = calculateAllValues(cartItems);

    const bill_discount = 0;

    const products = formatCartItems(cartItems);

    const eventItems = products.map((productItem, index) => ({
      item_id: cartItems[index].product_code,
      item_list_id: "cart",
      item_name: cartItems[index].title,
      item_brand: "Avarya",
      item_category: cartItems[index].category,
      item_category2: cartItems[index].sub_category,
      quantity: productItem.quantity,
      price: productItem.unit_price,
    }));

    const eventData = {
      currency: "INR",
      value: to2Decimal(total_amount - bill_discount),
      items: eventItems,
    };

    trackAddToCartWishlist("view_cart", eventData);
  }

  const renderTableContent = () => {
    if (
      state.status === STATUSES.LOADING &&
      (state.type === "fetch-cart" || state.type === "refresh")
    ) {
      return (
        <tr>
          <td colSpan={CART_CONSTANTS.COLS?.length + 1} className="text-center">
            <Loader />
          </td>
        </tr>
      );
    }

    if (cartItems?.length > 0) {
      return cartItems.map((_: ICartItem, i: number) => (
        <CartItem key={i} index={i} />
      ));
    }

    return (
      <tr>
        <td colSpan={CART_CONSTANTS.COLS?.length + 1} className="text-center">
          No Products
        </td>
      </tr>
    );
  };

  const renderCart = () => {
    return state.status === STATUSES.IDLE &&
      (state.type === "fetch-cart" || state.type === "refresh") &&
      cartItems?.length === 0 ? (
      <GlobalEmpty
        title="Empty Cart"
        message="Your Cart is Empty!<br/>Let's fill it up with your favorite snacks and sweets. Start exploring now and add some delicious treats to your cart!"
        btnText="Continue Shopping"
        action={() => router.push("/")}
      />
    ) : state.status === STATUSES.ERROR &&
      (state.type === "fetch-cart" || state.type === "refresh") ? (
      <GlobalError
        title="Error Loading Cart"
        message="Cart Unavailable<br/>We’re sorry, but we couldn’t load the items in your cart. Please refresh the page, or check back later. We appreciate your patience!"
      />
    ) : (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12 mb-15">
            <div className="d-flex justify-content-between align-items-end">
              <div>
                <h3>Shopping Cart</h3>
                <h6 className="pt-2 text-body">
                  Kindly check your order before checkout
                </h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-9">
            <Table
              parentClassName="shopping-summery"
              tableClassName="table-wishlist"
            >
              <TableHeader
                cols={CART_CONSTANTS.COLS}
                rowClassName="main-heading"
              />
              <TableBody>{renderTableContent()}</TableBody>
            </Table>
          </div>
          <div className="col-lg-3 col-md-12 pb-30">
            <div className="border p-md-4 p-15 border-radius cart-totals">
              <CartSummary
                title={"Cart Total"}
                cartItems={cartItems}
                shipping_cost={0}
                showShipping={false}
                showTotalItems={true}
              />
              <div className="text-center text-sm my-2">
                Shipping calculated at checkout
              </div>
              <Link
                href={canProceedToCheckout ? API_ROUTES.CHECKOUT : "#"}
                className={`btn btn-sm w-100 ${
                  !cartItems.length ||
                  !canProceedToCheckout ||
                  (state.status === STATUSES.LOADING &&
                    state.type === "fetch-cart") ||
                  (state.status === STATUSES.LOADING &&
                    state.type?.includes("delete-cartItem"))
                    ? "disabled"
                    : ""
                }`}
              >
                Proceed To Checkout
              </Link>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    router.refresh();

    dispatch(
      setWholeCart({
        onSuccess: (cartItems) => trackViewCart(cartItems),
        local: !is_logged_in,
      })
    );
  }, [is_logged_in]);

  return (
    <section>
      <div className="container section-padding-12 pt-30">{renderCart()}</div>
    </section>
  );
};

export { Cart };
