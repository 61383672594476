import {
  apiRequestClient,
  capitalizeFirstLetter,
  constructSlug,
  customRevalidateTag,
  formatProduct,
  IAddReview,
  IDBProduct,
  IReviewData,
  jsonDataToFormData,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATUSES,
  Toast,
  TOAST_CONSTANTS,
} from "@/utils";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { FormikHelpers } from "formik";
import * as Sentry from "@sentry/nextjs";
import { fetchProfileDetails } from "./authSlice";

const initialState = {
  quickView: {
    state: false,
    data: null,
    type: "",
  },
  nonce: null,
  offCanvas: {
    state: false,
    component: null as string | null,
  },
  state: {
    status: STATUSES.IDLE as string,
    type: null as null | string,
  },
  carouselData: {} as any,
  filteredProducts: {} as any,
  productReviews: {} as IReviewData,
  filter: {
    sort_by: "all",
    limit: 12,
    is_jain: 0,
    page: 1,
    query: "",
    taste: "",
    rating: "all",
    widgetIndex: 0,
  },
  currentPageData: {
    data: null,
    page: 1,
  } as {
    data: any;
    page: number;
  },
  loading_state: false,
  controller: null as any,
  toggleMobileMenu: false,
  maxPageLimit: 5,
  minPageLimit: 1,
  totalPages: {} as any,
  count: {} as any,
  placedOrderId: null,
  searchOptions: [] as any[],
  scroll: false,
};

export const fetchCurrentPageData = createAsyncThunk(
  "common/fetchCurrentPageData",
  async (
    {
      data,
      page,
      slug,
      isFrontPage = false,
      isLoggedIn = false,
    }: {
      page: number;
      slug: string;
      data?: any;
      isFrontPage: boolean;
      isLoggedIn: boolean;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      if (data) {
        return {
          data,
          page: 1,
        };
      }
      dispatch(
        setCommonState({ status: STATUSES.LOADING, type: "fetch-widgets" })
      );

      const endpoint = `/api/pageData/?page=${page}&slug=${slug}&limit=4`;

      const response = isLoggedIn
        ? await apiRequestClient({
            url: endpoint,
            method: REQUEST_METHOD.GET,
            contentType: REQUEST_CONTENT_TYPE.APP_JSON,
          })
        : await fetch(endpoint, {
            method: REQUEST_METHOD.GET,
            next: {
              revalidate: 3600,
            },
          });

      let result: any;

      if (isLoggedIn) {
        result = response;
      } else {
        result = await response.json();
      }

      dispatch(
        setCommonState({ status: STATUSES.IDLE, type: "fetch-widgets" })
      );

      return result;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      return {
        data: null,
        page: 1,
      };
    }
  }
);

export const fetchSearchResults = createAsyncThunk(
  "common/fetchSearchResults",
  async (
    { query }: { query: string },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      common: { controller: abortController },
    } = getState() as RootState;

    if (abortController) {
      abortController.abort();
    }

    dispatch(
      setCommonState({ status: STATUSES.LOADING, type: "fetch-search-results" })
    );

    const controller = new AbortController();
    dispatch(setControllerState(controller));

    const endpoint = `/api/search/?query=${query}`;

    try {
      const response = await apiRequestClient({
        url: endpoint,
        method: REQUEST_METHOD.GET,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        signal: controller.signal,
      });

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: "fetch-search-results",
        })
      );

      return response;
    } catch (error: any) {
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: "fetch-search-results",
        })
      );
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    }
  }
);

export const setInitialListingData = createAsyncThunk(
  "common/setInitialListingData",
  async (
    {
      widgetIndex,
      data,
      total_pages,
    }: { widgetIndex: number; data: any; total_pages: number },
    { rejectWithValue }
  ) => {
    try {
      return { data: data, widgetIndex: widgetIndex, total_pages: total_pages };
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    }
  }
);

export const fetchFilteredProducts = createAsyncThunk(
  "common/fetchFilteredProducts",
  async (
    {
      filters,
      url,
      search = false,
      widgetIndex,
    }: {
      filters: {
        sort_by: string;
        limit: number;
        is_jain: number;
        page: number;
        query: string;
        taste: string;
      };
      url: string;
      search?: boolean;
      widgetIndex: number;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      common: { controller: abortController },
    } = getState() as RootState;

    if (abortController) {
      abortController.abort();
    }

    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: `fetch-products-${widgetIndex}`,
      })
    );

    const controller = new AbortController();
    dispatch(setControllerState(controller));

    const containsCategorySubCategory =
      url.includes("category") || url.includes("subcategory");

    const recommendedProducts = url.includes("recommended");

    const endpoint = recommendedProducts
      ? `${url}&page=${filters.page}`
      : search
      ? `/api/search/?prevURL=${encodeURI(url)}&page=${filters.page}${
          filters.is_jain ? `&jain=1` : ""
        }&limit=${filters.limit || 12}`
      : `/api/search/${
          containsCategorySubCategory ? `?prevURL=${encodeURI(url)}&` : "?"
        }page=${filters.page}${filters.query ? `&query=${filters.query}` : ""}${
          filters.is_jain ? `&jain=1` : ""
        }&limit=${filters.limit || 12}${
          filters.sort_by !== "all" ? `&sort_by=${filters.sort_by}` : ""
        }${filters.taste ? `&taste=${filters.taste}` : ""}`;

    try {
      const response = await fetch(endpoint, {
        method: REQUEST_METHOD.GET,
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
        signal: controller.signal,
      }).then((res) => res?.json());

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    } finally {
      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: `fetch-products-${widgetIndex}`,
        })
      );
    }
  }
);

export const fetchProductReviews = createAsyncThunk(
  "common/fetchProductReviews",
  async (
    {
      filters,

      url,
    }: {
      filters: {
        limit: number;
        page: number;
        query: string;
        rating?: any;
      };
      url: String;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      common: { controller: abortController },
    } = getState() as RootState;

    dispatch(
      setCommonState({ status: STATUSES.LOADING, type: "fetch-reviews" })
    );

    if (abortController) {
      abortController.abort();
    }

    const controller = new AbortController();
    dispatch(setControllerState(controller));

    const endpoint = `${url}&page=${filters.page}${
      filters.rating !== "all" ? `&rating=${filters.rating}` : ""
    }&review_status=pending`;

    try {
      const response = await apiRequestClient({
        url: endpoint,
        method: REQUEST_METHOD.GET,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        signal: controller.signal,
      });

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    } finally {
      dispatch(
        setCommonState({ status: STATUSES.IDLE, type: "fetch-reviews" })
      );
    }
  }
);

export const fetchInitialCarouselData = createAsyncThunk(
  "common/fetchInitialCarouselData",
  async ({
    data,
  }: {
    data: {
      url: string;
      type: string;
      page: number;
      initialData: any;
    }[];
  }) => {
    const temp: { [key: string]: any } = {};

    // await Promise.all(
    //   data.map(async (item) => {
    //     const response = await fetch(
    //       `${STATIC_CONSTANTS.ENV_CONSTANTS.SERVER_URL}${item.url}&page=${item.page}`,
    //       {
    //         method: REQUEST_METHOD.GET,
    //         headers: {
    //           "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
    //         },
    //       }
    //     ).then((res) => res?.json());

    //     const data = item.url.includes("subcategory")
    //       ? response.result.results.map((sub_category: any) => ({
    //           title: sub_category.display_name,
    //           img: sub_category.icon,
    //           desc: sub_category.short_summary,
    //           link: constructSlug([
    //             sub_category.product_category.meta_slug || "",
    //             sub_category.meta_slug,
    //           ]),
    //           alt: sub_category.icon_alt,
    //           hasDesc: true,
    //         }))
    //       : response.result.results.map((product: IDBProduct) =>
    //           formatProduct(product)
    //         );

    //     temp[item.type] = {
    //       data: data,
    //       count: response.result.count,
    //       page: item.page,
    //     };
    //   })
    // );

    data.map((item) => {
      const initialData = item.url.includes("subcategory")
        ? item.initialData?.results?.map((sub_category: any) => ({
            title: sub_category.display_name,
            img: sub_category.icon,
            desc: sub_category.short_summary,
            link: constructSlug([
              sub_category.product_category.meta_slug || "",
              sub_category.meta_slug,
            ]),
            alt: sub_category.icon_alt,
            hasDesc: true,
          }))
        : item.initialData.results.map((product: IDBProduct) =>
            formatProduct(product)
          );

      temp[item.type] = {
        data: initialData,
        count: item.initialData.count,
        page: item.page,
      };
    });

    return temp;
  }
);

export const fetchCarouselData = createAsyncThunk(
  "common/fetchCarouselData",
  async (
    {
      url,
      type,
      page,
    }: {
      url: string;
      type: string;
      page: number;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: type,
      })
    );
    const {
      common: { carouselData },
    } = getState() as RootState;

    let temp = JSON.parse(JSON.stringify(carouselData));

    try {
      const response = await fetch(`${url}&page=${page}`, {
        method: REQUEST_METHOD.GET,
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
        },
      }).then((res) => res?.json());

      if (Object.keys(response).length === 1) {
        dispatch(
          setCommonState({
            status: STATUSES.ERROR,
            type: type,
          })
        );

        return carouselData;
      }

      const data = url.includes("subcategory")
        ? response.result.results.map((sub_category: any) => ({
            title: sub_category.display_name,
            img: sub_category.icon,
            desc: sub_category.short_summary,
            link: constructSlug([
              sub_category.product_category.meta_slug || "",
              sub_category.meta_slug,
            ]),
            alt: sub_category.icon_alt,
            hasDesc: true,
          }))
        : response.result.results.map((product: IDBProduct) =>
            formatProduct(product)
          );

      const currentCarouselData = temp[type];

      if (currentCarouselData !== undefined) {
        temp = {
          ...temp,
          [type]: {
            data: [...temp[type].data, ...data],
            count: response.result.count,
            page: page,
          },
        };
      } else {
        Object.assign(temp, {
          [type]: {
            data: data,
            count: response.result.count,
            page: 1,
          },
        });
      }

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: type,
        })
      );

      return temp;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: type,
        })
      );
    }
  }
);

export const contactUs = createAsyncThunk(
  "common/contactUs",
  async (
    {
      params,
      onSuccess,
    }: {
      params: any;
      onSuccess: () => void;
    },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(setCommonState({ status: STATUSES.LOADING, type: "contact-us" }));

    try {
      const response = await apiRequestClient({
        url: "/api/contact_us/",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.FORM_DATA,
        data: params,
      });

      dispatch(setCommonState({ status: STATUSES.IDLE, type: "contact-us" }));

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(setCommonState({ status: STATUSES.ERROR, type: "contact-us" }));
    }
  }
);

export const addReview = createAsyncThunk(
  "common/addReview",
  async (
    {
      params,
      actions,
    }: {
      params: { data: IAddReview; product: string };
      actions: FormikHelpers<IAddReview>;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      common: { filter },
    } = getState() as RootState;

    dispatch(setCommonState({ status: STATUSES.LOADING, type: "add-review" }));

    try {
      const response = await apiRequestClient({
        url: "/api/reviews/",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data: params.data,
      });

      actions.resetForm();

      dispatch(setQuickView({ state: false, type: "", data: null }));

      dispatch(setCommonState({ status: STATUSES.IDLE, type: "add-review" }));

      if (params.product) {
        dispatch(
          fetchProductReviews({
            filters: filter,
            url: `/api/reviews/?query=${params.product}`,
          })
        );
      }

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(setCommonState({ status: STATUSES.ERROR, type: "add-review" }));
    } finally {
      customRevalidateTag("reviews");
    }
  }
);

export const uploadImage = createAsyncThunk(
  "common/uploadImage",
  async (
    {
      params,
      updatePropsValues,
      setUrl,
    }: {
      params: {
        folder_name: string;
        file_name: string;
        file?: File;
      };
      updatePropsValues?: (value: any) => void;
      setUrl?: React.Dispatch<React.SetStateAction<string>>;
    },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: "upload-image",
      })
    );
    setUrl && setUrl("");
    try {
      const response: any = await fetch(`/api/upload/`, {
        method: REQUEST_METHOD.POST,
        headers: {
          "Content-Type": REQUEST_CONTENT_TYPE.FORM_DATA,
        },
        body: JSON.stringify({
          folder_name: params.folder_name,
          file_name: params.file_name,
        }),
      }).then((res) => res?.json());

      setUrl && setUrl(response.url);

      if (params.file) {
        const formData: any = jsonDataToFormData(
          Object.assign({ ...response.fields }, { file: params.file })
        );

        try {
          const img_response = await fetch(response.url, {
            method: REQUEST_METHOD.POST,
            body: formData,
            headers: {
              "Content-Type": REQUEST_CONTENT_TYPE.FORM_DATA,
            },
          });

          updatePropsValues &&
            updatePropsValues(
              encodeURI(
                `${response.url}${params.folder_name}/${params.file_name}`
              )
            );

          dispatch(
            setCommonState({
              status: STATUSES.IDLE,
              type: "upload-image",
            })
          );
        } catch (error: any) {
          Sentry.captureException(new Error(JSON.stringify(error)));
          dispatch(
            setCommonState({
              status: STATUSES.ERROR,
              type: "upload-image",
            })
          );
          updatePropsValues && updatePropsValues("");
          rejectWithValue(error.message);
        }
      }
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: "upload-image",
        })
      );
      rejectWithValue(error.message);
    }
  }
);

export const getGSTInvoice = createAsyncThunk(
  "common/getGSTInvoice",
  async (
    {
      setGstInvoice,
    }: { setGstInvoice: React.Dispatch<React.SetStateAction<boolean>> },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      auth: { profile_details },
    } = getState() as RootState;

    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: "gst-invoice",
      })
    );

    try {
      if (
        profile_details &&
        Object.keys(profile_details)?.length > 0 &&
        Boolean(profile_details?.gstin)
      ) {
        setGstInvoice(true);
      } else {
        dispatch(
          fetchProfileDetails({
            onSuccess: (applyGST) =>
              applyGST
                ? setGstInvoice(true)
                : dispatch(
                    setQuickView({
                      state: true,
                      data: null,
                      type: "business-details",
                    })
                  ),
          })
        );
      }
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: "gst-invoice",
        })
      );
      rejectWithValue(error.message);
    }
  }
);

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    resetCarouselData: (state) => {
      state.carouselData = {};
    },

    resetCurrentPageData: (state) => {
      state.currentPageData = initialState.currentPageData;
    },

    resetSearchResults: (state) => {
      state.searchOptions = [];
    },

    setControllerState: (state, action) => {
      state.controller = action.payload;
    },

    setFilteredProducts: (state, action) => {
      state.filteredProducts = action.payload;
    },

    setFilters: (
      state,
      action: {
        payload: {
          sort_by: string;
          limit: number;
          is_jain: number;
          page: number;
          query: string;
          taste: string;
          rating: any;
          widgetIndex: number;
        };
      }
    ) => {
      state.filter = action.payload;
    },
    resetFilters: (state) => {
      state.filter = {
        sort_by: "all",
        limit: 12,
        is_jain: 0,
        page: 1,
        query: "",
        taste: "",
        rating: "all",
        widgetIndex: 0,
      };
    },
    setPlacedOrderId: (state, action) => {
      state.placedOrderId = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setMinPageLimitState: (state, action) => {
      state.minPageLimit = action.payload;
    },
    setMaxPageLimitState: (state, action) => {
      state.maxPageLimit = action.payload;
    },

    setLoadingState: (state, action) => {
      state.loading_state = action.payload;
    },

    setScroll: (state, action) => {
      state.scroll = action.payload;
    },

    resetPageLimit: (state) => {
      state.maxPageLimit = 5;
      state.minPageLimit = 1;
    },
    setNonce: (state, action) => {
      state.nonce = action.payload;
    },

    resetReviews: (state) => {
      state.productReviews = {} as IReviewData;
    },

    setCommonState: (
      state,
      action: {
        payload: {
          status: string;
          type: string;
        };
      }
    ) => {
      state.state = action.payload;
    },
    toggleMobileMenuState: (state) => {
      state.toggleMobileMenu = !state.toggleMobileMenu;
    },
    setOffCanvas: (
      state,
      action: {
        payload: {
          state: boolean;
          component: string | null;
        };
      }
    ) => {
      state.offCanvas = action.payload;
    },
    setQuickView: (
      state,
      action: {
        payload: {
          state: boolean;
          data: any;
          type: string;
        };
      }
    ) => {
      state.quickView = action.payload;
    },
  },
  extraReducers(builder) {
    const appendContent = (currentContent: any, newContent: any) => [
      ...currentContent,
      ...newContent,
    ];

    builder
      .addCase(fetchCurrentPageData.fulfilled, (state, action) => {
        if (action.payload.result) {
          const { content, ...rest } = action.payload.result;
          state.currentPageData = {
            data: {
              ...rest,
              content: appendContent(
                state.currentPageData?.data?.content ?? [],
                content
              ),
            },
            page: action.meta.arg.page,
          };
        } else {
          state.currentPageData = action.payload;
        }
      })
      .addCase(fetchInitialCarouselData.fulfilled, (state, action) => {
        state.carouselData = action.payload;
      })
      .addCase(fetchCarouselData.fulfilled, (state, action) => {
        state.carouselData = action.payload;
      })

      .addCase(setInitialListingData.fulfilled, (state, action) => {
        state.filteredProducts = state.filteredProducts ?? [];
        state.filteredProducts[action.meta.arg.widgetIndex] =
          action.meta.arg.data;

        state.totalPages[action.meta.arg.widgetIndex] =
          action.meta.arg.total_pages;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        if (action.payload) {
          const { result, message } = action.payload;
          const reviewList = state.productReviews.review_list ?? [];
          reviewList.push(result);
          state.productReviews.review_list = reviewList;
          Toast({
            message: capitalizeFirstLetter(message),
            type: TOAST_CONSTANTS.SUCCESS,
          });
        }
      })
      .addCase(contactUs.fulfilled, (state, action) => {
        if (action.payload) {
          Toast({
            message: `${capitalizeFirstLetter(action.payload.message)}`,
            type: TOAST_CONSTANTS.SUCCESS,
          });
          action.meta.arg.onSuccess();
        }
      })

      .addCase(fetchFilteredProducts.fulfilled, (state, action) => {
        if (action.payload) {
          if (action.meta.arg.url.includes("recommended")) {
            state.filteredProducts[action.meta.arg.widgetIndex] =
              action.payload.result?.results?.map(formatProduct) ?? [];
            state.totalPages[action.meta.arg.widgetIndex] = Math.ceil(
              action.payload.result?.count / 12
            );
            state.count[action.meta.arg.widgetIndex] =
              action.payload.result?.count ?? 0;
          } else {
            const { product_list } = action.payload.result ?? {};
            state.filteredProducts[action.meta.arg.widgetIndex] =
              product_list?.results?.map(formatProduct) ?? [];
            state.totalPages[action.meta.arg.widgetIndex] = Math.ceil(
              product_list?.count / product_list?.page_size
            );
            state.count[action.meta.arg.widgetIndex] = product_list?.count ?? 0;
          }
        }
      })
      .addCase(fetchSearchResults.fulfilled, (state, action) => {
        state.searchOptions =
          action.payload?.result?.product_list?.results?.map(formatProduct) ??
          [];
      })
      .addCase(fetchProductReviews.fulfilled, (state, action) => {
        if (action.payload) {
          const { review_list, average_rating, review_statistics } =
            action.payload.result ?? {};
          state.productReviews = {
            average_rating: average_rating ?? 0,
            review_list: review_list?.results ?? [],
            review_statistics,
            total_pages: Math.ceil((review_list?.count ?? 0) / 10),
          };
        }
      });
  },
});

export const {
  resetCarouselData,

  resetCurrentPageData,

  resetSearchResults,

  resetReviews,

  setNonce,

  setControllerState,

  setFilteredProducts,
  setFilters,
  resetFilters,

  setPlacedOrderId,

  setLoadingState,

  setScroll,

  setTotalPages,
  setMinPageLimitState,
  setMaxPageLimitState,
  resetPageLimit,

  setCommonState,

  toggleMobileMenuState,

  setOffCanvas,
  setQuickView,
} = commonSlice.actions;

export default commonSlice.reducer;
