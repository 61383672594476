"use client";

import { CustomSelect } from "@/components/core-components";
import { usePagination } from "@/utils";
import { Form, Formik } from "formik";
import React from "react";

const OrderFilters = () => {
  const { additionalFilter, searchParams } = usePagination();

  // const yearOptions = filterNullElements(
  //   Array.from({ length: 5 }, (_, i) => {
  //     const year = moment().subtract(i, "years").format("YYYY");
  //     if (Number(year) < 2024) return null;
  //     return { value: year, label: year };
  //   })
  // );

  const yearOptions = Array.from({ length: 5 }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return year < 2024 ? null : { value: String(year), label: String(year) };
  }).filter(Boolean);

  return (
    <div className="sort-by-product-area">
      <div className="sort-by-cover">
        <Formik
          initialValues={{
            year: searchParams.get("year") || new Date().getFullYear(),
          }}
          onSubmit={(values, actions) => {}}
        >
          {(sortProps) => (
            <Form>
              <CustomSelect
                name="year"
                formikProps={sortProps}
                options={yearOptions}
                inline={{
                  title: "Year:",
                  icon: "fi-rs-apps-sort",
                }}
                onBlur={(e: any) => {
                  additionalFilter("year", e.target.value.toString());
                }}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export { OrderFilters };
