"use client";

import Script from "next/script";
import Image from "next/image";
import React from "react";

const FacebookPixel = ({ nonce }: { nonce: string | null }) => {
  const pixelId = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL;

  if (!pixelId) {
    console.error(
      "Facebook Pixel ID is not defined in the environment variables."
    );
    return null;
  }

  return (
    <React.Fragment>
      <Script
        id="facebook-pixel"
        strategy="afterInteractive"
        nonce={nonce || ""}
      >
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${pixelId}');
          fbq('track', 'PageView');
        `}
      </Script>
      <noscript>
        <Image
          height={1}
          width={1}
          alt="facebook-pixel"
          className="d-none"
          src={`https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`}
        />
      </noscript>
    </React.Fragment>
  );
};

export { FacebookPixel };
