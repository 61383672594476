"use client";

import Image from "next/image";
import { useState } from "react";

import filled_star from "@media/icons/star-filled.png";
import outlined_star from "@media/icons/star-outlined.png";

const StarRating = ({
  totalStars = 5,
  value,
  setFieldValue,
}: {
  totalStars?: number;
  value: number;
  setFieldValue?: any;
}) => {
  const [hoverRating, setHoverRating] = useState(0);

  const getStarImage = (index: number) => {
    if (hoverRating >= index || (!hoverRating && value >= index)) {
      return filled_star;
    } else {
      return outlined_star;
    }
  };

  const handleMouseEnter = (index: number) => setHoverRating(index);
  const handleMouseLeave = () => setHoverRating(0);
  const handleClick = (index: number) => {
    setFieldValue("rating", index);
  };

  return (
    <div className="d-flex flex-row gap-2">
      {Array.from({ length: totalStars }, (_, index) => index + 1).map(
        (star) => (
          <Image
            width={100}
            height={100}
            key={star}
            className="h-100 w-100"
            src={getStarImage(star)}
            alt={`${star} Star`}
            style={{ width: "50px", cursor: "pointer" }}
            onMouseEnter={() => handleMouseEnter(star)}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleClick(star)}
          />
        )
      )}
    </div>
  );
};

export { StarRating };
