"use client";

import { setLoadingState, setOffCanvas, useAppDispatch } from "@/store";
import { STATIC_CONSTANTS } from "@/utils";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect } from "react";

const Breadcrumb = ({
  homeElement,
  separator,
  listClasses,
  activeClasses,
  capitalizeLinks,
}: {
  homeElement: React.ReactNode;
  separator: React.ReactNode;
  listClasses?: string;
  activeClasses?: string;
  capitalizeLinks?: boolean;
}) => {
  const dispatch = useAppDispatch();

  const paths = usePathname();
  const pathNames = paths.split("/").filter((path) => path);

  const displayBreadCrumb =
    pathNames.filter((value) =>
      STATIC_CONSTANTS.DISABLED_BREADCRUMB.includes(value)
    )?.length > 0;

  useEffect(() => {
    dispatch(setLoadingState(false));
    return () => {
      dispatch(setLoadingState(false));
    };
  }, []);

  return !displayBreadCrumb && pathNames.length > 0 ? (
    <section className="page-header breadcrumb-wrap">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ul>
              <li className={listClasses}>
                <Link
                  href={"/"}
                  onClick={() => {
                    dispatch(setLoadingState(true));
                    dispatch(
                      setOffCanvas({
                        component: "",
                        state: false,
                      })
                    );
                  }}
                >
                  {homeElement}
                </Link>
              </li>
              {pathNames.length > 0 && separator}
              {pathNames.map((link, index) => {
                let href = `/${pathNames.slice(0, index + 1).join("/")}`;

                let itemLink = capitalizeLinks
                  ? decodeURIComponent(
                      link[0].toUpperCase() + link.slice(1, link.length)
                    ).replace(/-/g, " ")
                  : decodeURIComponent(link).replace(/-/g, " ");

                let itemClasses =
                  paths === href || itemLink === "Edit"
                    ? `${listClasses} ${activeClasses}`
                    : listClasses;

                return (
                  <React.Fragment key={`breadcrumb-item-${index}`}>
                    <li className={itemClasses}>
                      {pathNames.length === index + 1 || itemLink === "Edit" ? (
                        <React.Fragment>{itemLink}</React.Fragment>
                      ) : (
                        <Link
                          href={href}
                          onClick={() => {
                            dispatch(setLoadingState(href !== "#"));
                            dispatch(
                              setOffCanvas({
                                component: "",
                                state: false,
                              })
                            );
                          }}
                        >
                          {itemLink}
                        </Link>
                      )}
                    </li>
                    {pathNames.length !== index + 1 && separator}
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};

export { Breadcrumb };
