"use client";

import React from "react";

interface IProps {
  cols:
    | string[]
    | React.ReactNode[]
    | { col: string; className: string; colSpan?: number }[];
  className?: string;
  rowClassName?: string;
}

const TableHeader = ({ cols, className, rowClassName }: IProps) => {
  return (
    <thead className={`${className ? className : ""}`}>
      <tr className={`${rowClassName ? rowClassName : ""}`}>
        {cols.map(
          (
            col:
              | string
              | React.ReactNode
              | { col: string; className: string; colSpan?: number },
            index: number
          ) =>
            col && (
              <th
                colSpan={
                  typeof col === "object"
                    ? (
                        col as {
                          col: string;
                          className: string;
                          colSpan: number;
                        }
                      ).colSpan || 1
                    : 1
                }
                key={index}
                scope="col"
                className={`${
                  typeof col === "object" && Object.keys(col).length > 1
                    ? (col as { col: string; className: string }).className
                    : ""
                }`}
              >
                {typeof col === "object"
                  ? (col as { col: string; className: string }).col
                  : col}
              </th>
            )
        )}
      </tr>
    </thead>
  );
};

export { TableHeader };
