"use client";

import Image from "next/image";
import { usePathname } from "next/navigation";
import React from "react";

const Banner = ({
  src,
  alt,
  blurURL,
  height = 600,
  width = 1600,
  className,
  imageClassName,
  parentClassName,
  children,
  widgetIndex,
}: {
  src: string;
  alt: string;
  blurURL?: string;
  height: number;
  width: number;
  className?: string;
  imageClassName?: string;
  parentClassName?: string;
  children?: React.ReactNode;
  widgetIndex: number;
}) => {
  const issubCategory =
    usePathname()
      ?.split("/")
      ?.filter((name) => name)?.length > 1;

  return (
    <section className={`${parentClassName ? parentClassName : ""}`}>
      <div className={`full-width-banner ${className ? className : ""}`}>
        {children}
        <Image
          alt={alt}
          src={src}
          width={issubCategory ? 1440 : 1320}
          height={issubCategory ? 290 : 445}
          className={`w-100 h-100 ${imageClassName ? imageClassName : ""}`}
          // loading="eager"
          priority
        />
      </div>
    </section>
  );
};

export default Banner;
