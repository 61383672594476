const ProgressBar = ({ label, width }: { label: string; width: number }) => {
  return (
    <div className="progress">
      <span>{label}</span>
      <div
        aria-label="progress-bar"
        className="progress-bar"
        role="progressbar"
        style={{ width: `${width}%` }}
        aria-valuenow={width}
        aria-valuemin={0}
        aria-valuemax={100}
      ></div>
    </div>
  );
};

export { ProgressBar };
