"use client";

import { GeneralSliderProps } from "@/utils";
import { HorizontalListing } from "../HorizontalListing";
import { SwiperSlide } from "swiper/react";
import { CategoryItem } from "./CategoryItem";
import { Fragment, useMemo } from "react";

const CategoryHorizontalListing = ({
  last,
  data: initialData,
  title,
  prevBtn,
  nextBtn,
  parentClassName,
  btnClassName,
  sliderClassName,
  slideClassName,
  sliderId,
  slidesPerView = 6,
  spaceBetween,
  autoplay,
  containerClassName,
  hasDesc = false,
  pagination,
  widgetIndex = 0,
  type,
  lengthToRender = 0,
  onNavigationNext,
}: GeneralSliderProps) => {
  const renderCategory = useMemo(() => {
    return initialData?.map((item: any, index: number) => {
      return (
        <Fragment key={`category-${index + 1}`}>
          <SwiperSlide
            className={`category-${index + 1} ${slideClassName || ""}`}
            virtualIndex={index}
          >
            <CategoryItem
              item={item}
              index={index}
              hasDesc={hasDesc}
              widgetIndex={widgetIndex || 0}
            />
          </SwiperSlide>
          {!widgetIndex &&
          initialData.length % 6 === 0 &&
          initialData.length - 1 === index ? (
            <SwiperSlide
              className={`category-${
                index + 1
              } d-flex flex-column justify-content-center align-items-center align-self-center align-content-center`}
              virtualIndex={index + 1}
            >
              <span
                className="text-primary spinner-border"
                aria-hidden="true"
              />
            </SwiperSlide>
          ) : null}
        </Fragment>
      );
    });
  }, [initialData?.length]);

  return (
    <Fragment key={`${widgetIndex || 0}-${type}-category-listing-fragment`}>
      <HorizontalListing
        type={type}
        autoplay={autoplay}
        title={title}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        prevBtn={prevBtn}
        nextBtn={nextBtn}
        parentClassName={parentClassName}
        btnClassName={btnClassName}
        sliderClassName={sliderClassName}
        sliderId={sliderId}
        pagination={pagination}
        dataLength={initialData?.length}
        toRender={initialData?.length >= lengthToRender}
        onNavigationNext={onNavigationNext}
        last={last}
      >
        {initialData?.length >= lengthToRender ? renderCategory : null}
      </HorizontalListing>
    </Fragment>
  );
};

export { CategoryHorizontalListing };
