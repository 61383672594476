"use client";

import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";

import { Swiper } from "swiper/react";

import { SliderProps } from "@/utils";

import React from "react";

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

const Slider = ({
  type,
  children,
  className,
  btnClassName,
  prevBtn,
  nextBtn,
  slidesPerView = 1,
  spaceBetween = 50,
  autoplay,
  pagination: currentPagination,
  centered = false,
  onNavigationNext,
  dataLength = slidesPerView,
  breakpoints,
  swiperRef,
}: SliderProps) => {
  const hidePagination = dataLength < 6 && type?.includes("categories");

  return (
    <React.Fragment>
      <Swiper
        ref={swiperRef}
        key={type}
        pagination={currentPagination}
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        navigation={{
          prevEl: `.${prevBtn}`,
          nextEl: `.${nextBtn}`,
        }}
        preventInteractionOnTransition={true}
        breakpoints={
          breakpoints !== null
            ? {
                0: { slidesPerView: 2 },
                768: { slidesPerView: 4 },
                1024: { slidesPerView: slidesPerView },
              }
            : undefined
        }
        onReachEnd={(s: any) => {
          onNavigationNext && onNavigationNext(s);
        }}
        centeredSlides={centered}
        watchOverflow={false}
        loop={autoplay !== undefined && dataLength >= slidesPerView * 2}
        loopPreventsSliding={false}
        autoplay={autoplay}
        className={className}
      >
        {children}
      </Swiper>

      <div
        className={`slider-arrow ${btnClassName ? btnClassName : ""} ${
          hidePagination ? "visually-hidden" : ""
        }`}
      >
        <span className={`slider-btn slider-prev slick-arrow ${prevBtn}`}>
          <i className="fi-rs-angle-left"></i>
        </span>
        <span className={`slider-btn slider-next slick-arrow ${nextBtn}`}>
          <i className="fi-rs-angle-right"></i>
        </span>
      </div>
    </React.Fragment>
  );
};

export { Slider };
