"use client";

import React, { useState, useRef } from "react";
import { FormikProps, useField } from "formik";

interface CustomSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  onlyLabel?: boolean;
  name: string;
  label?: string;
  formValue?: any;
  className?: string;
  selectClassName?: string;
  options: any[];
  formikProps?: FormikProps<any>;
  inline?: {
    title: string;
    icon: string;
  };
  isRequired?: boolean;
  placeholder?: string;
  small?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void;
}

const CustomSelect = ({
  name,
  label,
  formValue = "",
  options,
  formikProps,
  placeholder,
  onChange,
  onBlur,
  className,
  selectClassName,
  isRequired,
  inline,
  onlyLabel = false,
  small = false,
  ...props
}: CustomSelectProps) => {
  const [field, meta] = formikProps ? useField(name) : [null, null];
  const [internalValue, setInternalValue] = useState(formValue);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (!onlyLabel) {
      setInternalValue(e.target.value);
      onChange?.(e);
      if (formikProps) {
        formikProps.setFieldValue(name, e.target.value, true);
      }
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLSelectElement>) => {
    if (!onlyLabel) {
      setInternalValue(e.target.value);
      onBlur?.(e);
      if (formikProps) {
        formikProps.setFieldValue(name, e.target.value);
      }
    }
  };

  const value = formikProps ? field?.value : internalValue;
  const error = formikProps ? meta?.touched && meta?.error : null;

  const optionsToRender = [{ value: "", label: placeholder }, ...options];

  const renderOptions = (placeholder ? optionsToRender : options).map(
    (option, index) => (
      <option
        className={`${small ? "test-sm" : ""}${inline ? "font-13" : ""}`}
        key={`${option.value}-${index}`}
        value={option.value}
      >
        {option.label}
      </option>
    )
  );

  const selectRef = useRef<HTMLSelectElement | null>(null);

  return inline ? (
    <div className={`${className ? className : "sort-by-product-wrap"}`}>
      <div className="sort-by">
        <span>
          <i className={inline.icon}></i>
          {inline.title}
        </span>
      </div>
      <div className="sort-by-dropdown-wrap custom-select">
        <select
          aria-label={name}
          className={`select-active font-13 ${
            selectClassName ? selectClassName : ""
          } ${meta?.touched && meta?.error ? "is-invalid" : ""}`}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          {...field}
          {...props}
        >
          {/* <option disabled selected hidden style={{ display: "none" }}>
            {placeholder ? placeholder : "Select an option ..."}
          </option> */}
          {renderOptions}
        </select>
      </div>
    </div>
  ) : (
    // w-100
    <div className={`${className ? className : "custom_select"}`}>
      {label && <label htmlFor={name}>{label}</label>}
      {isRequired ? <span className="text-danger">*</span> : ""}
      {!onlyLabel && !className && <i className="fi-rs-angle-down"></i>}
      <select
        id={name}
        className={`form-control select-active ${
          meta?.touched && meta?.error ? "is-invalid" : ""
        } ${selectClassName ? selectClassName : ""} ${
          onlyLabel ? "only-label" : ""
        }`}
        ref={selectRef}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={onlyLabel}
        {...field}
        {...props}
      >
        {/* {placeholder && <option value={""}>{placeholder}</option>} */}
        {renderOptions}
      </select>
      {error ? <div className="text-danger mb-2">{meta?.error}</div> : null}
    </div>
  );
};

export { CustomSelect };
