import Image from "next/image";

import not_found from "@media/page/page-404.png";

export const metadata = {
  title: "No Internet Connection | Avarya Retail Pvt Ltd",
  description: "No Internet Connection, Avarya Retail Pvt Ltd",
  robots: {
    index: false,
    follow: false,
  },
};

const NoInternetConnection = () => {
  return (
    <main className="main page-no-internet">
      <div className="page-content pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center">
              <p className="mb-20">
                <Image
                  width={500}
                  height={500}
                  src={not_found}
                  alt="no-internet-connection"
                  className="hover-up w-100 h-100"
                />
              </p>
              <h1 className="display-2 mb-30">Oops! No Internet Connection</h1>
              <p className="font-lg text-grey-700 mb-30">
                It seems you're not connected to the internet.
                <br />
                Please check your connection and try again to continue exploring
                our delectable treats!
              </p>
              <button
                className="btn btn-default submit-auto-width font-xs hover-up mt-30"
                onClick={() => window.location.reload()}
              >
                <i className="fi-rs-refresh ml-0 mr-5"></i> Retry Connection
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export { NoInternetConnection };
