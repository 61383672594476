"use client";

import React, { useEffect, useCallback } from "react";
import { ListingWithFilters } from "../Listing";
import {
  clearWishlist,
  removeFromWishlist,
  setWholeWishlist,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { STATUSES, Toast } from "@/utils";
import { useRouter } from "next/navigation";
import { GlobalEmpty } from "@/components/GlobalEmpty";
import { GlobalError } from "@/components/GlobalError";

const Wishlist = React.memo(() => {
  const dispatch = useAppDispatch();
  const { state } = useAppSelector((state) => state.common);
  const { wishlist } = useAppSelector((state) => state.wishlist);
  const router = useRouter();

  useEffect(() => {
    dispatch(setWholeWishlist());
  }, [dispatch]);

  const handleClearWishlist = useCallback(() => {
    dispatch(clearWishlist());
  }, [dispatch]);

  const handleRemoveFromWishlist = useCallback(
    (product: any) => {
      dispatch(
        removeFromWishlist({
          wishlist_id: product.wishlist_id,
          onSuccess: () => {
            Toast({
              message: "Removed from wishlist",
              icon: <i className="fi-rs-heart"></i>,
            });
          },
        })
      );
    },
    [dispatch]
  );

  const handleBrowseProducts = useCallback(() => {
    router.push("/");
  }, [router]);

  if (state.status === STATUSES.LOADING && state.type === "fetch-wishlist") {
    return (
      <div className="d-flex flex-row justify-content-center align-items-center">
        <span
          data-testid="loader"
          className="spinner-border text-primary m-2"
        ></span>
      </div>
    );
  }

  if (state.status === STATUSES.ERROR && state.type === "fetch-wishlist") {
    return (
      <GlobalError
        title="Error Loading Wishlist"
        message="Wishlist Unavailable We're sorry, but we couldn’t load the items in your wishlist right now. Please refresh the page, or check back later. Thank you for your understanding!"
      />
    );
  }

  if (wishlist?.length > 0) {
    return (
      <ListingWithFilters
        type="wishlist"
        serverFilters={true}
        data={wishlist}
        title="Your Wishlist"
        filters={["All", "Popular", "Trending"]}
        search={true}
        count={wishlist.length}
        total_pages={Math.ceil(wishlist.length / 10)}
        extraFilters={true}
        clearBtnText="Clear Wishlist"
        clearBtnClick={handleClearWishlist}
        individualDelete={handleRemoveFromWishlist}
      />
    );
  }

  return (
    <GlobalEmpty
      title="Empty Wishlist"
      message="Your Wishlist is Waiting!<br/>Start exploring our collection and add the items you love. Your favorites are just a click away!"
      btnText="Browse Products"
      action={handleBrowseProducts}
    />
  );
});

export { Wishlist };
