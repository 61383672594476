"use client";

import { FormikProps, useField } from "formik";
import React, { useState } from "react";
import Select, { NoticeProps, components } from "react-select";

interface MultiSelectProps {
  select?: boolean;
  disabled?: boolean;
  name: string;
  label?: string;
  isRequired?: boolean;
  designation?: boolean;
  selectClassName?: string;
  options: { value: string; label: string }[] | any[];
  custom?: {
    onChange?: (selectedOption: any) => void;
  };
  onChangeHandler?: any;
  onBlurHandler?: any;
  onInputChangeHandler?: any;
  onEndReached?: (props: any) => void;
  onFocusHandler?: React.FocusEventHandler<HTMLInputElement>;
  defaultValue?: any;
  addNewValue?: boolean;
  addNewComponent?: React.ReactNode;
  isLoading?: boolean;
  loadingMessage?: (obj: { inputValue: string }) => React.ReactNode;
  onMenuCloseHandler?: () => void;
  onKeyDownHandler?: (e: any) => void;
  valueToShow?: any;
  placeholder?: string;
  value?: any;
  inputValue?: string;
  mulitSelectRef?: any;
  tabIndex?: number;
  showNoOptionMsg?: boolean;
  formikProps?: FormikProps<any>;
  small?: boolean;
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  disabled,
  mulitSelectRef,
  select,
  custom,
  name,
  label,
  isRequired,
  options,
  selectClassName,
  onChangeHandler,
  onBlurHandler,
  onInputChangeHandler,
  designation,
  onEndReached,
  defaultValue,
  addNewValue,
  addNewComponent,
  onFocusHandler,
  isLoading,
  loadingMessage,
  onMenuCloseHandler,
  onKeyDownHandler,
  valueToShow,
  placeholder,
  value,
  inputValue,
  formikProps,
  tabIndex,
  showNoOptionMsg = false,
  small = false,
  ...props
}) => {
  const [isSearchable, setIsSearchable] = useState(true);
  const [isClearable, setIsClearable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [field, meta, helpers] = useField(name);

  const handleChange = (selectedOption: any) => {
    const selectedOptions = Array.isArray(selectedOption)
      ? selectedOption
      : [selectedOption];
    helpers.setValue(selectedOptions);
  };

  const NoOptionsMessage = (props: NoticeProps) => {
    return (
      <components.NoOptionsMessage {...props}>
        {addNewComponent}
      </components.NoOptionsMessage>
    );
  };

  return (
    <div
      className={`${
        selectClassName ? selectClassName : ""
      } input-group d-flex flex-column w-100`}
    >
      <div className="form-group mb-0">
        {label && (
          <label>
            {label}
            {isRequired ? <span className="text-danger">*</span> : ""}
          </label>
        )}
        <div data-testid={name}>
          <Select
            inputId={name}
            {...props}
            tabIndex={tabIndex}
            ref={mulitSelectRef}
            className={`${select ? "basic-multi-select" : "basic-single"}`}
            placeholder={placeholder}
            onFocus={onFocusHandler}
            onMenuScrollToBottom={onEndReached}
            classNamePrefix={"select"}
            closeMenuOnSelect={!select}
            inputValue={inputValue}
            components={
              options?.length === 0
                ? !showNoOptionMsg
                  ? {
                      Menu: () => null,
                      ClearIndicator: () => null,
                    }
                  : { ClearIndicator: () => null }
                : { NoOptionsMessage, ClearIndicator: () => null }
            }
            isMulti={select}
            styles={{
              input: (base) => ({
                ...base,
                height: "2rem",
                // padding: "0.75rem",
              }),
              option: (provided: any, state: any) => {
                return {
                  ...provided,
                  fontSize: "12px",
                };
              },
            }}
            options={options}
            // menuShouldScrollIntoView={true}
            // menuPosition="fixed"
            value={value ? value : field.value}
            onBlur={onBlurHandler}
            onChange={onChangeHandler ? onChangeHandler : handleChange}
            onInputChange={onInputChangeHandler}
            isSearchable={isSearchable}
            isClearable={isClearable}
            isDisabled={disabled}
            defaultValue={defaultValue}
            isLoading={isLoading}
            loadingMessage={loadingMessage}
            onMenuClose={onMenuCloseHandler}
            onKeyDown={onKeyDownHandler}
          />
        </div>
      </div>
    </div>
  );
};

export { MultiSelect };
