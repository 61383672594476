"use client";

import { IButtonProps } from "@/utils";
import React from "react";

const Button = ({
  className,
  textClassName,
  loading = false,
  text,
  ...props
}: IButtonProps) => {
  return (
    <button
      className={`${className ? className : ""} rounded-lg ${
        loading ? "disabled" : ""
      }`}
      {...props}
    >
      {loading && (
        <React.Fragment>
          <span
            className="spinner-border spinner-border-sm mr-2"
            aria-hidden="true"
          />
          &nbsp;
        </React.Fragment>
      )}
      <span className={`${textClassName ? textClassName : ""}`}>
        {loading ? `  ${text}` : text}
      </span>
    </button>
  );
};

export { Button };
