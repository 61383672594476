"use client";

import { Button, CustomInput, Loader } from "@/components/core-components";
import {
  applyDiscount,
  removeDiscount,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { ApplyDiscountSchema, BUTTON_CONSTANTS, STATUSES } from "@/utils";
import { Form, Formik } from "formik";
import React from "react";

const ApplyDiscount = () => {
  const dispatch = useAppDispatch();

  const { appliedDiscount } = useAppSelector((state) => state.cart);

  const { state: commonState } = useAppSelector((state) => state.common);

  return (
    <div className="coupon-section">
      <div className="heading_s1 mb-3">
        <h4>Apply Coupon</h4>
      </div>
      <div className="total-amount">
        <div className="left">
          <div className="coupon">
            <div className="form-row row justify-content-center">
              <Formik
                enableReinitialize={true}
                initialValues={{ code: appliedDiscount.discount_code || "" }}
                onSubmit={(values, actions) => {
                  dispatch(applyDiscount({ code: values.code, actions }));
                }}
                validationSchema={ApplyDiscountSchema}
              >
                {(props) => (
                  <Form>
                    <CustomInput
                      name="code"
                      formikProps={props}
                      disabled={appliedDiscount.discount_value > 0}
                      className="d-flex justify-content-space-between col-lg-12"
                      inputClassName="font-medium mr-20"
                      placeholder="Enter Your Coupon"
                      appendElement={
                        <Button
                          text={
                            commonState.status === STATUSES.LOADING &&
                            commonState.type === "apply-discount" ? (
                              <Loader />
                            ) : appliedDiscount.discount_value > 0 ? (
                              "Clear"
                            ) : (
                              "Apply"
                            )
                          }
                          disabled={
                            commonState.status === STATUSES.LOADING &&
                            commonState.type === "apply-discount"
                          }
                          className="btn btn-sm"
                          type={BUTTON_CONSTANTS.BUTTON}
                          onClick={() => {
                            if (appliedDiscount.discount_value > 0) {
                              dispatch(removeDiscount());
                              props.resetForm();
                            } else if (props.values.code) {
                              props.submitForm();
                            }
                          }}
                        />
                      }
                    />
                  </Form>
                )}
              </Formik>
            </div>
            {/* {appliedDiscount.discount_value > 0 && (
              <div className="d-flex flex-row justify-content-start gap-1">
                Applied discount of
                <span className="text-primary">
                  ₹{appliedDiscount.discount_value}
                </span>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ApplyDiscount };
