import {
  REQUEST_METHOD,
  STATUSES,
  apiRequestClient,
  REQUEST_CONTENT_TYPE,
  API_ROUTES,
  customRevalidateTag,
  CountryCode,
  SetCartLocally,
  seperateFirstLastName,
  IAddress,
  IProfileDetails,
} from "@/utils";
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";
import { RootState } from "../store";
import {
  addToWishlist,
  resetPendingItemsToAdd,
  setWishlistCounter,
} from "./wishlistSlice";
import { resetCartState, setCartItemsCounter } from "./cartSlice";
import {
  setCommonState,
  setControllerState,
  setQuickView,
} from "./commonSlice";
import * as Sentry from "@sentry/nextjs";

const initialState = {
  auth_state: STATUSES.IDLE as string,
  logged_in_user: null as null | any,
  customer_details: null as null | any,
  access_token: null as null | any,
  refresh_token: null as null | any,
  register_details: "",
  verification_pending: false,
  profile_details: null as null | IProfileDetails,
};

export const fetchProfileDetails = createAsyncThunk(
  "auth/fetchProfileDetails",
  async (
    { onSuccess }: { onSuccess?: (value: boolean) => void },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({ status: STATUSES.LOADING, type: "fetch-profile" })
    );

    try {
      const response = await apiRequestClient({
        url: "/api/account/profile",
        method: REQUEST_METHOD.GET,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
      });

      dispatch(
        setCommonState({ status: STATUSES.IDLE, type: "fetch-profile" })
      );

      if (onSuccess) {
        onSuccess(Boolean(response.result.gstin));
      }

      return response.result;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(
        setCommonState({ status: STATUSES.ERROR, type: "fetch-profile" })
      );
    }
  }
);

export const updateProfileDetails = createAsyncThunk(
  "auth/updateProfileDetails",
  async (
    {
      data,
      onSuccess,
    }: {
      data: IProfileDetails;
      onSuccess?: () => void;
    },
    { dispatch, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: "update-profile",
      })
    );
    try {
      const response = await apiRequestClient({
        url: "/api/account/profile",
        method: REQUEST_METHOD.PUT,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data,
      });

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: "update-profile",
        })
      );

      onSuccess?.();

      return response.result;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: "update-profile",
        })
      );
    }
  }
);

export const fetchUserDetails = createAsyncThunk(
  "auth/fetchUserDetails",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setCommonState({ status: STATUSES.LOADING, type: "refresh" }));
    try {
      const response = await apiRequestClient({
        url: "/api/refresh",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
      });

      dispatch(setCommonState({ status: STATUSES.IDLE, type: "refresh" }));

      // dispatch(setWholeWishlist());
      // dispatch(setWholeCart({}));

      dispatch(setCartItemsCounter(response.result.cart_items_count));

      dispatch(setWishlistCounter(response.result.wishlist_items_count));

      return {
        access_token: response.result.access_token,
        refresh_token: response.result.refresh_token,
        customer_details: response.result.customer_details,
      };
    } catch (error: any) {
      dispatch(setCommonState({ status: STATUSES.ERROR, type: "refresh" }));
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (
    {
      data,
      update = false,
      router,
      onSuccess,
    }: {
      data: {
        email?: string;
        contact_number?: number;
        country?: number;
      };
      update?: boolean;
      router?: AppRouterInstance;
      onSuccess?: () => void;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      common: { controller: abortController },
    } = getState() as RootState;

    if (abortController) {
      abortController.abort();
    }

    const controller = new AbortController();
    dispatch(setControllerState(controller));

    try {
      const response = await apiRequestClient({
        url: update ? "/api/account/profile/update" : "/api/auth/login",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data,
        showToast: update,
        signal: controller.signal,
      });

      onSuccess && onSuccess();

      if (update) {
        return response;
      }

      if (!Array.isArray(response.result) && !response.result.email) {
        dispatch(setVerificationPending(true));
      }

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      const custom_error = JSON.parse(error);

      if (!update && custom_error.error.status === 417) {
        dispatch(setRegisterDetails(data.email || data.contact_number));
        router?.replace("/account/register");
      }

      rejectWithValue(error.message);
    }
  }
);

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await fetch("/api/auth/logout", {
        method: REQUEST_METHOD.GET,
      }).then((res) => res?.json());

      if (response) {
        dispatch(resetCartState());
        dispatch(setCartItemsCounter(0));
        dispatch(setWishlistCounter(0));
      }
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "auth/verifyOtp",
  async (
    {
      data,
      update = false,
      onSuccess,
    }: {
      data: {
        email?: string;
        email_otp?: number;
        contact_number?: number;
        mobile_otp?: number;
        country?: number;
      };
      update?: boolean;
      onSuccess?: () => void;
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      wishlist: { pendingItemsToAdd },
      cart: { cartItems },
      common: { controller: abortController },
    } = getState() as RootState;

    if (abortController) {
      abortController.abort();
    }

    const controller = new AbortController();
    dispatch(setControllerState(controller));

    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: "verify-otp",
      })
    );

    try {
      const response = await apiRequestClient({
        url: update
          ? "/api/account/profile/update?verify=true"
          : "/api/auth/otp",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data,
        signal: controller.signal,
      });

      if (update) {
        dispatch(setQuickView({ state: false, data: null, type: "" }));
        customRevalidateTag("profile");

        return response.result;
      }

      onSuccess && onSuccess();

      dispatch(setVerificationPending(false));

      dispatch(setCartItemsCounter(response.result.cart_items_count));

      dispatch(setWishlistCounter(response.result.wishlist_items_count));

      if (cartItems.length > 0) {
        try {
          const response = await apiRequestClient({
            url: "/api/cart",
            method: REQUEST_METHOD.PUT,
            contentType: REQUEST_CONTENT_TYPE.APP_JSON,
            data: {
              cart_items: cartItems.map((item) => ({
                product_id: item.id,
                quantity: item.quantity,
                weight_id: item.weight_id,
              })),
            },
          });
        } catch (error: any) {
          Sentry.captureException(new Error(JSON.stringify(error)));
          rejectWithValue(error.message);
        }
      }

      try {
        if (pendingItemsToAdd.length > 0) {
          pendingItemsToAdd.forEach((item) => {
            dispatch(addToWishlist({ product_id: item }));
          });
        }
      } catch (error: any) {
        Sentry.captureException(new Error(JSON.stringify(error)));
        rejectWithValue(error.message);
      } finally {
        dispatch(resetPendingItemsToAdd());
      }

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    } finally {
      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: "verify-otp",
        })
      );
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/registerUser",
  async (
    {
      data,
      router,
    }: {
      data: {
        name: string;
        contact_number: string;
        country: string;
        // whatsapp_number: string;
        email: string;
      };
      router: AppRouterInstance;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await apiRequestClient({
        url: "/api/auth/register",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data,
      });

      if (response) {
        dispatch(
          loginUser({
            data: {
              contact_number: Number(response.result.contact_number),
              country: Number(response.result.country),
            },
            router,
          })
        );
      }

      dispatch(setVerificationPending(false));

      // const response = await fetch("/api/auth/register", {
      //   method: REQUEST_METHOD.POST,
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(data),
      // }).then((res) => res?.json());

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setRegisterDetails: (state, action) => {
      state.register_details = action.payload;
    },
    setVerificationPending: (state, action) => {
      state.verification_pending = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(logoutUser.fulfilled, (state) => {
        SetCartLocally([]);
        state.access_token = null;
        state.refresh_token = null;
        state.customer_details = null;
        state.logged_in_user = null;
      })

      .addCase(fetchUserDetails.fulfilled, (state, action: any) => {
        if (action.payload) {
          state.access_token = action.payload.access_token;
          state.refresh_token = action.payload.refresh_token;
          state.customer_details = action.payload.customer_details;
          state.logged_in_user = action.payload.customer_details;
        }
      })

      .addCase(registerUser.fulfilled, (state, action) => {
        if (action.payload) {
          action.meta.arg.router.replace(API_ROUTES.LOGIN);
          state.customer_details = action.payload.result;
        }
        state.auth_state = STATUSES.IDLE;
      })

      .addCase(verifyOtp.fulfilled, (state, action) => {
        if (action.meta.arg.update) {
          const { first_name, last_name } = seperateFirstLastName(
            action.payload.customer_details.name
          );

          state.profile_details = {
            id: action.payload.customer_details.id,
            first_name: first_name,
            last_name: last_name,
            email: action.payload.customer_details.email,
            contact_number: action.payload.customer_details.contact_number,
            created_at: action.payload.customer_details.created_at,
            country: action.payload.customer_details.country,
          };
          return;
        }

        if (action.payload) {
          state.logged_in_user = action.payload.result.customer_details;
        }
      })

      .addMatcher(
        isAnyOf(fetchProfileDetails.fulfilled, updateProfileDetails.fulfilled),
        (state, action: any) => {
          if (action.payload) {
            const { first_name, last_name } = seperateFirstLastName(
              action.payload.name
            );

            state.profile_details = {
              first_name: first_name,
              last_name: last_name,
              ...action.payload,
            };
          }
        }
      )

      .addMatcher(isAnyOf(registerUser.pending, loginUser.pending), (state) => {
        state.auth_state = STATUSES.LOADING;
      })
      .addMatcher(
        isAnyOf(registerUser.rejected, loginUser.rejected),
        (state) => {
          state.auth_state = STATUSES.ERROR;
        }
      )

      .addMatcher(
        isAnyOf(
          registerUser.fulfilled,
          verifyOtp.fulfilled,
          loginUser.fulfilled
        ),
        (state, action: any) => {
          SetCartLocally([]);
          state.auth_state = STATUSES.IDLE;
        }
      );
  },
});

export const { setRegisterDetails, setVerificationPending } = authSlice.actions;

export default authSlice.reducer;
