import React from "react";
import Link from "next/link";
import Image from "next/image";

const BannerSlide = ({
  item,
  index,
  widgetIndex,
  category = false,
}: {
  item: any;
  index: number;
  widgetIndex: number;
  category?: boolean;
}) => {
  return (
    <div
      className={`single-hero-slider ${
        !category && `rectangle slider-content-center`
      } single-animation-wrap`}
    >
      {item.link ? (
        <Link href={`/${item.link}`}>
          <Image
            className="w-100 h-100"
            src={item.img}
            alt={item?.alt || ""}
            width={item.width || 1320}
            height={item.height || 445}
            loading={index > 1 ? "lazy" : "eager"}
            // priority
          />
        </Link>
      ) : (
        <Image
          className="w-100 h-100"
          alt={item?.alt || ""}
          src={item.img}
          width={item.width || 1320}
          height={item.height || 445}
          loading={index > 1 ? "lazy" : "eager"}
          // priority
        />
      )}
    </div>
  );
};

export { BannerSlide };
