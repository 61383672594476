"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

const AccountLayout = ({ children }: { children: React.ReactNode }) => {
  const pathNames = usePathname().split("/");

  const dontShowTabs =
    (pathNames.includes("orders") &&
      pathNames.some((path) => Number.isInteger(parseInt(path)))) ||
    pathNames.includes("signin") ||
    pathNames.includes("register");

  if (dontShowTabs) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  const navItems = [
    {
      href: "/account/profile",
      icon: "fi-rs-user",
      label: "Profile",
      match: "profile",
    },
    {
      href: "/account/orders",
      icon: "fi-rs-shopping-bag",
      label: "Orders",
      match: "orders",
    },
    {
      href: "/account/addresses",
      icon: "fi-rs-marker",
      label: "My Addresses",
      match: "addresses",
    },
  ];

  return (
    <div className="page-content ptb-30">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 m-auto">
            <div className="row">
              <div className="col-md-2 mb-40">
                <div className="dashboard-menu">
                  <ul className="nav flex-column" role="tablist">
                    {navItems.map(({ href, icon, label, match }) => (
                      <li className="nav-item" key={href}>
                        <Link
                          href={href}
                          className={`nav-link ${
                            pathNames.includes(match) ? "active" : ""
                          }`}
                          // prefetch
                        >
                          <i className={`${icon} mr-10`}></i>
                          {label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-10">
                <div className="tab-content account dashboard-content pl-0">
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { AccountLayout };
