"use client";

import { useAppSelector } from "@/store";
import React from "react";

const OffCanvas = ({
  children,
  type,
}: {
  children: React.ReactNode;
  type?: string;
}) => {
  const {
    offCanvas: { state: open, component },
  } = useAppSelector((state) => state.common);

  return (
    <div className={`offCanvas ${open && type === component ? "open" : ""}`}>
      <div className="content pr-0">{children}</div>
    </div>
  );
};

export { OffCanvas };
