"use client";

import { Button, Loader } from "@/components/core-components";
import {
  setLastSelectedAddress,
  setQuickView,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { BUTTON_CONSTANTS, IAddress } from "@/utils";
import Image from "next/image";
import React, { useMemo } from "react";
import { STATUSES } from "../../../utils/constants";

import icon_plus from "@media/icons/icon-plus-small.svg";
import icon_minus from "@media/icons/icon-minus-small.svg";

interface IAddressItem {
  state: boolean;
  id: number;
}

export interface AddressState {
  shipping: IAddressItem;
  billing: IAddressItem;
}

type AddressKey = keyof AddressState;

interface IProps {
  title: string;
  type: AddressKey;
  address: AddressState;
  setAddress: React.Dispatch<React.SetStateAction<AddressState>>;
  sameAddress: boolean;
  setSameAddress: React.Dispatch<React.SetStateAction<boolean>>;
  gstInvoice?: boolean;
}

const UserAddressList = ({
  title,
  type,
  address: addressState,
  setAddress,
  sameAddress,
  setSameAddress,
  gstInvoice,
}: IProps) => {
  const dispatch = useAppDispatch();

  const { addresses, lastSelectedAddress } = useAppSelector(
    (state) => state.address
  );

  const { state } = useAppSelector((state) => state.common);

  const renderAddressItem = useMemo(() => {
    return addresses?.length > 0 ? (
      <table>
        <tbody>
          {addresses?.map((address: IAddress, index: number) => {
            return (
              <tr key={index}>
                <td className="border-none custome-radio p-0">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={`address-${type}-${index}`}
                    id={`address-${type}-${index}`}
                    checked={address.id === addressState[type].id}
                    disabled={
                      state.status === STATUSES.LOADING &&
                      state.type === "shipping-cost"
                    }
                    onClick={() => {
                      setSameAddress(false);
                      setAddress({
                        ...addressState,
                        [type]: {
                          ...addressState[type],
                          // state: false,
                          id: Number(address.id),
                        },
                      });
                      if (type === "shipping") {
                        dispatch(
                          setLastSelectedAddress({ id: Number(address.id) })
                        );
                        setAddress({
                          shipping: {
                            state: true,
                            id: Number(address.id),
                          },
                          billing: {
                            state: false,
                            id: Number(address.id),
                          },
                        });
                      }
                    }}
                  />
                  <label
                    className="form-check-label font-lg pl-30"
                    htmlFor={`address-${type}-${index}`}
                  ></label>
                </td>
                <td className="border-none font-sm">
                  <span className="text-extra-dark font-lg fw-600 mr-10">
                    {`${address.first_name} ${address.last_name}`}
                  </span>
                  <address>{`${address?.address_line_1} ${address?.address_line_2} ${address?.landmark}`}</address>
                  <p className="font-xs mb-0">
                    {`${address?.city?.city}, ${address?.state?.state}, ${address?.pincode} , ${address?.country?.country}`}
                  </p>
                </td>
                <td className="border-none">
                  <Button
                    text={"Edit Address"}
                    type={BUTTON_CONSTANTS.BUTTON}
                    className="btn btn-xs font-lg btn btn-xs no-wrap"
                    onClick={() => {
                      dispatch(
                        setQuickView({
                          state: true,
                          data: { id: address.id, setAddress: setAddress },
                          type: "address",
                        })
                      );
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    ) : null;
  }, [
    addressState[type],
    addressState[type]?.id,
    addresses?.length,
    state.status,
    state.type,
  ]);

  return (
    <div className={`${type}-address`}>
      <div className="faq-item mb-20">
        <div
          className="faq-question mb-20"
          onClick={() => {
            setAddress({
              ...addressState,
              [type]: {
                ...addressState[type],
                state: !addressState[type].state,
              },
            });
          }}
        >
          <h6>{title}</h6>
          {(type === "shipping" || !sameAddress) && (
            <Image
              height={24}
              width={24}
              src={
                (
                  type === "shipping"
                    ? addressState.shipping.state
                    : addressState.billing.state
                )
                  ? icon_minus
                  : icon_plus
              }
              alt={
                (
                  type === "shipping"
                    ? addressState.shipping.state
                    : addressState.billing.state
                )
                  ? "minus"
                  : "plus"
              }
            />
          )}
        </div>

        {state.status === STATUSES.LOADING &&
          state.type === "fetch-addresses" && (
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Loader />
            </div>
          )}
        {(type === "shipping"
          ? addressState.shipping.state
          : addressState.billing.state && !sameAddress) && (
          <div className="addresses-radio">
            <div>{renderAddressItem}</div>
            <div>
              <Button
                text={"Add New Address"}
                type={BUTTON_CONSTANTS.BUTTON}
                className="btn btn-xs"
                onClick={() => {
                  dispatch(
                    setQuickView({
                      state: true,
                      data: setAddress,
                      type: "address",
                    })
                  );
                }}
              />
            </div>
          </div>
        )}
        {type === "billing" && (
          <div className="custome-checkbox mt-20">
            <input
              type="checkbox"
              className="form-check-input text-xs"
              name="isBillingSame"
              value="isBillingSame"
              disabled={gstInvoice}
              checked={sameAddress}
              onClick={() => {
                setAddress({
                  shipping: {
                    ...addressState.shipping,
                    state: true,
                  },
                  billing: {
                    state: true,
                    id:
                      lastSelectedAddress ||
                      addresses?.find((item) => item.set_as_default === 1)
                        ?.id ||
                      addresses?.[0]?.id ||
                      0,
                  },
                });
                setSameAddress(!sameAddress);
              }}
              id="isBillingSame"
            />
            <label
              className="form-check-label font-xl user-select-none"
              htmlFor="isBillingSame"
            >
              Same as Shipping Address
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export { UserAddressList };
