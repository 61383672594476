import React from "react";
import { Loader } from "@/components/core-components";

function DynamicLoader() {
  return (
    <div className="loader-overlay text-center align-content-center">
      <div className="text-brand">
        <Loader size="lg" />
      </div>
    </div>
  );
}

export { DynamicLoader };
