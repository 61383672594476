"use client";

import Link from "next/link";
import React, { useEffect, useMemo } from "react";
import { OrderTable } from "./Checkout";
import { IOrderAddress, STATUSES, trackPurchase } from "@/utils";
import Image from "next/image";
import { fetchSuccessfulOrder, useAppDispatch, useAppSelector } from "@/store";
import { useRouter } from "next/navigation";
import { OrderInfo } from "./Orders";
import { GlobalError } from "../GlobalError";

import thank_you from "@media/page/thank-you.png";

const Success = () => {
  const dispatch = useAppDispatch();

  const router = useRouter();

  const { placedOrderId } = useAppSelector((state) => state.common);

  const { status, successfulOrder } = useAppSelector((state) => state.order);

  if (!placedOrderId) {
    router.push("/");
    return <></>;
  }

  const shippingAddress = useMemo(() => {
    return successfulOrder?.addresses?.find(
      (address: IOrderAddress) => address.address_type === "shipping"
    );
  }, [Object.keys(successfulOrder || {})?.length]);

  const billingAddress = useMemo(() => {
    return successfulOrder?.addresses?.find(
      (address: IOrderAddress) => address.address_type === "billing"
    );
  }, [Object.keys(successfulOrder || {})?.length]);

  const successfullPayment = useMemo(() => {
    return successfulOrder?.payment_data?.find(
      (item) =>
        item.payment_status === "success" &&
        item.payment_amount === successfulOrder?.payable_amount
    );
  }, [Object.keys(successfulOrder || {})?.length]);

  useEffect(() => {
    dispatch(
      fetchSuccessfulOrder({
        order_id: Number(placedOrderId),
        onSuccess: (orderDetails) => {
          trackPurchase(orderDetails);
        },
      })
    );
  }, []);

  return (
    <section>
      <div className="container pt-30">
        {status === STATUSES.LOADING ? (
          <div className="row justify-content-center">
            <span
              data-testid="loader"
              className={`spinner-border text-primary m-2`}
            ></span>
          </div>
        ) : successfulOrder ? (
          <div className="row pb-30">
            <div className="col-lg-6">
              <Image
                width={100}
                height={100}
                src={thank_you}
                alt="thank-you"
                className="hover-up order-success-img"
              />
              <h4 className="mb-10">Thank you for ordering from Avarya!</h4>
              <p className="font-lg text-grey-700 mb-15">
                Your delicious treats are on your way! You can track your orders
                on <Link href="/account/orders">Order History</Link> page
              </p>
              <Link
                href="/"
                className="btn btn-sm btn-default submit-auto-width font-xs hover-up mb-15"
                // prefetch
              >
                Continue Shopping
              </Link>
              <OrderInfo
                orderDetails={successfulOrder}
                isOrderDetailPage={false}
                sectionclassName="col-lg-12 col-md-12"
                shippingAddress={shippingAddress}
                billingAddress={billingAddress}
                cartItems={successfulOrder?.ordered_products || []}
                successfullPayment={successfullPayment}
              />
            </div>
            <div className="col-lg-6">
              <div className="border p-15 cart-totals ml-30 mb-30">
                <div className="col-lg-12 col-md-12 order-summary-section">
                  <div className="heading_s1 mb-3">
                    <h5 className="mb-10">Ordered Items</h5>
                  </div>
                </div>
                <OrderTable
                  cartItems={successfulOrder?.ordered_products || []}
                  orderDetails={true}
                />
              </div>
            </div>
          </div>
        ) : (
          <GlobalError
            title="Order Information Unavailable"
            message="We’re sorry, but there was an issue loading your order details. Don’t worry—your order has been placed successfully!.We appreciate your patience and understanding."
            btnText="Continue Shopping"
            action={() => router.push("/")}
          />
        )}
      </div>
    </section>
  );
};

export { Success };
