"use client";

import { FC, useEffect } from "react";
import { BannerSlide } from "../group-components";

interface CarouselProps {
  items: {
    img: string;
    height: string | number;
    width: string | number;
    blurURL?: string;
    alt: string;
    title: string;
    description: string;
    btnText: string | undefined;
    link: string;
    small: boolean;
    className: string;
    videoUrl: string;
  }[];
  interval?: number;
  prevBtn?: string;
  nextBtn?: string;
}

const CustomCarousel: FC<CarouselProps> = ({
  items,
  interval = 2500,
  prevBtn,
  nextBtn,
}) => {
  useEffect(() => {
    require("bootstrap/js/dist/carousel");
  }, []);

  return (
    <div
      id="singleCarousel"
      className="carousel slide"
      data-bs-ride="carousel"
      data-bs-touch="true"
    >
      <div className="carousel-indicators">
        {items.map((_, index) => (
          <button
            key={index}
            type="button"
            data-bs-target="#singleCarousel"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-current={index === 0 ? "true" : undefined}
            aria-label={`Slide ${index + 1}`}
          ></button>
        ))}
      </div>
      <div className="carousel-inner">
        {items.map((item, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? "active" : ""}`}
            data-bs-interval={interval}
          >
            <BannerSlide
              item={item}
              index={index + 1}
              widgetIndex={index}
              category={false}
            />
          </div>
        ))}
      </div>
      <button
        className={`carousel-control-prev ${prevBtn || ""}`}
        type="button"
        data-bs-target="#singleCarousel"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className={`carousel-control-next ${nextBtn || ""}`}
        type="button"
        data-bs-target="#singleCarousel"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default CustomCarousel;
