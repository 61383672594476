"use client";

import { Modal } from "react-responsive-modal";
import { ProductReviews, ReviewForm, ReviewModal } from "./Listing";
import {
  resetFilters,
  setQuickView,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { AddressForm, UpdateDetails } from "./Account";

const QuickView = () => {
  const {
    quickView: { data, state, type },
  } = useAppSelector((state) => state.common);

  const dispatch = useAppDispatch();

  const renderModal = () => {
    switch (type) {
      case "address":
        return <AddressForm modalData={{ state: state, data: data }} />;

      case "change-details":
        return <UpdateDetails modalData={{ state: state, data: data }} />;

      // case "product":
      //   return (
      //     <ProductDetails
      //       reviews={{
      //         average_rating: 0,
      //         review_list: [],
      //         review_statistics: [],
      //         total_pages: 0,
      //       }}
      //       product={data || ({} as IProduct)}
      //       quickView={state}
      //     />
      //   );

      case "product-review":
        return <ReviewForm modalData={{ state: state, data: data }} />;

      case "review-detail":
        <ReviewModal modalData={{ state: state, data: data }} />;

      case "review-list":
        return <ProductReviews modalData={{ state: state, data: data }} />;

      case "business-details":
        return (
          <AddressForm
            isBusiness={true}
            modalData={{ state: true, data: null }}
          />
        );

      default:
        return null;
    }
  };

  if (!state) {
    return null;
  }

  return (
    <Modal
      open={state}
      classNames={{
        modalContainer: type === "review-list" ? "fullScreenModal" : "",
        modal: type === "review-list" ? "fullScreenModal" : "",
      }}
      center
      styles={{
        modal:
          type === "review-list"
            ? { height: "100vh", width: "100vw" }
            : type === "product-review" ||
              type === "address" ||
              type === "business-details"
            ? { minHeight: "60vh", width: "60vw" }
            : type === "change-details"
            ? { maxWidth: "500px", maxHeight: "400px", overflowY: "visible" }
            : undefined,
        modalContainer: {
          maxHeight: "100vh",
          maxWidth: "100vw",
        },
      }}
      onClose={() => {
        if (type === "review-list") {
          dispatch(resetFilters());
        }

        // Trigger exit animation before closing
        const modalElement = document.querySelector(`.fullScreenModal`);
        if (modalElement) {
          modalElement.classList.add(".exitAnimation");
          setTimeout(() => {
            dispatch(setQuickView({ state: false, data: null, type: "" }));
          }, 300); // Duration of the exit animation
        } else {
          dispatch(setQuickView({ state: false, data: null, type: "" }));
        }
      }}
    >
      {state && <div className="quick-view">{renderModal()}</div>}
    </Modal>
  );
};

export { QuickView };
