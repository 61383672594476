"use client";

import { Button } from "./core-components";
import { BUTTON_CONSTANTS } from "@/utils";
import { useRouter } from "next/navigation";
import parse from "html-react-parser";

const GlobalError = ({
  title,
  message,
  icon = <i className="fi-rs-exclamation text-warning"></i>,
  btnText = "Refresh Page",
  action,
}: {
  title: string;
  message: string;
  icon?: React.ReactNode;
  btnText?: string;
  action?: () => void;
}) => {
  const router = useRouter();

  const refreshPage = () => {
    router.refresh();
  };

  return (
    <div className="col-xl-8 col-lg-10 col-md-12 m-auto text-center pt-190 pb-190">
      <div className="d-flex flex-row justify-content-center align-items-center gap-3">
        <h1 className="mb-20 ">{icon}</h1>
        <h1 className="display-5 mb-30">{title}</h1>
      </div>
      <p className="font-lg text-grey-700 mb-30">{parse(message)}</p>
      {btnText && (
        <Button
          className="btn btn-default submit-auto-width font-xs hover-up"
          text={btnText}
          type={BUTTON_CONSTANTS.BUTTON}
          onClick={action || refreshPage}
        />
      )}
    </div>
  );
};

export { GlobalError };
