"use client";

import React, { useEffect, useRef } from "react";
import { CategoryItem } from "../group-components";

const MultipleCarousel: React.FC<{ items: any[]; widgetIndex: number }> = ({
  items,
  widgetIndex,
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const handleSlide = (e: any) => {
    const carouselElement = carouselRef.current;
    if (!carouselElement) return;

    const itemsPerSlide = parseInt(
      carouselElement.getAttribute("data-maximum-items-per-slide") || "6"
    );
    const totalItems = carouselElement.querySelectorAll(
      ".custom-carousel-item"
    ).length;
    const reserve = 1; // Do not change
    const itemsContainer = carouselElement.querySelector(
      ".custom-carousel-inner"
    );
    const remainingItems = itemsPerSlide + reserve - (totalItems - e.to);

    if (remainingItems > 0) {
      for (let i = 0; i < remainingItems; i++) {
        const items = carouselElement.querySelectorAll(".custom-carousel-item");
        const itemToMove = e.direction === "left" ? items[i] : items[0];
        itemsContainer?.appendChild(itemToMove);
      }
    }
  };

  useEffect(() => {
    const carouselElement = carouselRef.current;
    if (!carouselElement) return;

    carouselElement.addEventListener("slide.bs.carousel", handleSlide);

    return () => {
      carouselElement.removeEventListener("slide.bs.carousel", handleSlide);
    };
  }, []);

  return (
    <div className="custom-container py-3">
      <div className="custom-carousel-wrapper p-3 m-n3 overflow-hidden">
        <div
          ref={carouselRef}
          id="custom-carousel-multiple"
          className="custom-carousel slide"
          data-bs-ride="carousel"
          data-maximum-items-per-slide="6"
        >
          <div className="custom-carousel-row position-relative">
            <div className="custom-carousel-inner row mx-0">
              {items.map((item, index) => (
                <div
                  key={index}
                  className={`custom-carousel-item col-sm-6 col-md-4 col-lg-3 col-xl-2 ${
                    index === 0 ? "active" : ""
                  }`}
                >
                  <CategoryItem
                    item={item}
                    index={index}
                    hasDesc={true}
                    widgetIndex={widgetIndex || 0}
                  />
                </div>
              ))}
            </div>
            <button
              className="custom-carousel-control-prev w-auto px-5 px-xl-4"
              type="button"
              data-bs-target="#custom-carousel-multiple"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            {/* <a
              className="custom-carousel-control-prev w-auto px-5 px-xl-4"
              href="#custom-carousel-multiple"
              role="button"
              data-bs-slide="prev"
            >
              <span
                className="custom-carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a> */}
            <button
              className="custom-carousel-control-next w-auto px-5 px-xl-4"
              type="button"
              data-bs-target="#custom-carousel-multiple"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
            {/* <a
              className="custom-carousel-control-next w-auto px-5 px-xl-4"
              href="#custom-carousel-multiple"
              role="button"
              data-bs-slide="next"
            >
              <span
                className="custom-carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export { MultipleCarousel };
