"use client";

import { FormikProps, useField } from "formik";
import { Label } from "./Label";
import { useState } from "react";

interface TextAreaProps {
  label?: string;
  name: string;
  id?: string;
  rows: number;
  cols: number;
  placeholder: string;
  formikProps?: FormikProps<any>;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const TextArea = ({
  id,
  label,
  name,
  value,
  rows,
  cols,
  placeholder,
  formikProps,
  onChange,
}: TextAreaProps) => {
  const [field, meta] = formikProps ? useField(name) : [null, null];
  const [internalValue, setInternalValue] = useState(value);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInternalValue(e.target.value);
    onChange?.(e);
    if (formikProps) {
      formikProps.setFieldValue(name, e.target.value);
    }
  };

  const formValue = formikProps ? field?.value : internalValue;
  const formError = formikProps ? meta?.touched && meta?.error : null;

  return (
    <div className="form-group">
      <Label htmlFor={id} label={label} labelClassNames="form-label" />
      <textarea
        // className="form-control"
        placeholder={placeholder}
        name={name}
        id={id}
        rows={rows}
        cols={cols}
        value={formValue}
        onChange={handleChange}
      />
      {formError ? <div className="text-danger mb-2">{meta?.error}</div> : null}
    </div>
  );
};

export { TextArea };
