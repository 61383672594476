"use client";

import { Table, TableBody, TableHeader } from "@/components";
import { setLoadingState, setOffCanvas, useAppDispatch } from "@/store";
import {
  capitalizeFirstLetter,
  displayValue,
  formatDateTime,
  IOrderListing,
} from "@/utils";
import Link from "next/link";
import { useEffect } from "react";

const OrderListing = ({
  orders,
  total_pages,
}: {
  orders: IOrderListing[];
  total_pages: number;
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLoadingState(false));
  }, []);

  return (
    <div className="tab-pane fade active show">
      <div className="card order-history">
        <div className="card-header p-0 pb-15">
          <div className="mb-0 shop-product-fillter d-flex justify-content-between align-items-center">
            <div className="section-title style-2 mb-0">
              <h3>Your Orders</h3>
            </div>
            {/* {orders?.length > 0 && <OrderFilters />} */}
          </div>
        </div>
        {orders?.length > 0 ? (
          <div className="card-body p-0">
            <Table
              paginationClassName="pagination-area mt-15 mb-sm-5 mb-lg-0"
              pagination
              total_page={total_pages}
            >
              <TableHeader
                cols={[
                  { col: "Invoice Code", className: "bg-grey-9" },
                  { col: "Date", className: "bg-grey-9" },
                  { col: "Order Status", className: "bg-grey-9" },
                  { col: "Payment Status", className: "bg-grey-9" },
                  { col: "Total", className: "bg-grey-9" },
                  { col: "Actions", className: "bg-grey-9" },
                ]}
              />

              <TableBody>
                {orders.length > 0 ? (
                  orders.map((item, index) => (
                    <tr key={`order-item-${index}`}>
                      <td width={"5%"} className="text-center">
                        {item.invoice_code || "N/A"}
                      </td>
                      <td>{formatDateTime(item.created_at)}</td>
                      <td>{capitalizeFirstLetter(item.order_status)}</td>
                      <td>{capitalizeFirstLetter(item.payment_status)}</td>
                      <td>
                        <span className="rupee-symbol">₹</span>
                        {displayValue(item.payable_amount)}
                      </td>
                      <td className="d-flex">
                        <Link
                          href={`/account/orders/${item.id}`}
                          className="btn-small d-block"
                          prefetch
                          onClick={() => {
                            dispatch(setLoadingState(true));
                            dispatch(
                              setOffCanvas({
                                component: "",
                                state: false,
                              })
                            );
                          }}
                        >
                          View Details
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>No Orders</tr>
                )}
              </TableBody>
            </Table>
          </div>
        ) : (
          "No Orders"
        )}
      </div>
    </div>
  );
};

export { OrderListing };
