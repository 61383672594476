"use client";

import React, { useState } from "react";
import Select, { InputActionMeta, NoticeProps, components } from "react-select";
import { Loader } from "./Loader";

interface MultiSelectProps {
  tabIndex?: number;
  toggle?: boolean;
  selectRef?: any;
  select?: boolean;
  disabled?: boolean;
  label?: string;
  isRequired?: boolean;
  designation?: boolean;
  selectClassName?: string;
  options: { value: string; label: string }[] | any[];
  onChangeHandler?: (value: any, actions: any) => void;
  onBlurHandler?: (event: React.FocusEvent) => void;
  onInputChangeHandler?: (inputValue: any) => void;
  onEndReached?: (props: any) => void;
  value?: any;
  inputValue?: string;
  setValue?: (value: any) => void;
  placeholder?: string;
  defaultValue?: any;
  addNewValue?: boolean;
  addNewComponent?: React.ReactNode;
  menuCloseHandler?: () => void;
  autoFocus?: boolean;
  loadingMessage?: any;
  isLoading?: boolean;
  valueToShow?: any;
  name?: string;
  small?: boolean;
  inputAppendTxt?: string;
  onFocusHandler?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDownHandler?: React.KeyboardEventHandler<HTMLDivElement>;
  formatOptions?: boolean;
  instanceId: string;
}

const CustomMultiSelect = ({
  tabIndex,
  formatOptions = false,
  name,
  toggle,
  selectRef,
  disabled,
  autoFocus = false,
  select,
  instanceId,
  label,
  options,
  selectClassName,
  onChangeHandler,
  onBlurHandler,
  onInputChangeHandler,
  designation,
  onEndReached,
  value,
  inputValue: controlledInputValue,
  setValue,
  defaultValue,
  placeholder,
  addNewValue,
  addNewComponent,
  menuCloseHandler,
  loadingMessage,
  isLoading,
  valueToShow,
  small,
  inputAppendTxt = "",
  onFocusHandler,
  onKeyDownHandler,
}: MultiSelectProps) => {
  const [isSearchable, setIsSearchable] = useState(true);
  const [isClearable, setIsClearable] = useState(true);

  const handleChange = (selectedOption: any) => {
    const selectedOptions = Array.isArray(selectedOption)
      ? selectedOption
      : [selectedOption];
    selectedOption !== null && setValue && setValue(selectedOptions);
    onInputChangeHandler?.("");
  };

  // const selectedOption = options.filter((item) => item.value === valueToShow);

  const handleInputChange = (inputText: string, meta: InputActionMeta) => {
    if (meta.action !== "input-blur" && meta.action !== "menu-close") {
      onInputChangeHandler && onInputChangeHandler(inputText);
    }
  };

  const formatOptionLabel = (data: any) => (
    <div
      // href={data.value.includes("search") ? data.value : `/${data.value}`}
      className="d-flex flex-row justify-content-between align-items-center"
      // onClick={() => {
      //   data.updateSearchTerm();
      // }}
    >
      <div className="d-flex flex-row gap-2 align-items-center">
        {!data.label.includes("See") && (
          <span className="text-muted">
            <i className="fi-rs-search"></i>
          </span>
        )}
        <h6 className="fw-300 font-13">{data.label}</h6>
      </div>
    </div>
  );

  const NoOptionsMessage = (props: NoticeProps) => {
    return (
      <components.NoOptionsMessage {...props}>
        {addNewValue && (controlledInputValue !== "" || options.length === 0)
          ? addNewComponent
          : "No products found"}
      </components.NoOptionsMessage>
    );
  };

  const CustomLoader = () => (
    <span className="text-primary">
      <Loader />
    </span>
  );

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {inputAppendTxt ? (
            <h6 className="text-dark mb-0">{inputAppendTxt}</h6>
          ) : (
            <span className="d-flex align-items-center px-2">
              <i className="fi-rs-search"></i>
            </span>
          )}
        </components.DropdownIndicator>
      )
    );
  };

  const optionHeight = 30;
  const maxVisibleOptions = 11;

  const calculatedMenuHeight = options.length * optionHeight;
  const menuHeight = Math.min(
    calculatedMenuHeight,
    maxVisibleOptions * optionHeight
  );

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      flexDirection: inputAppendTxt ? "row-reverse" : undefined,
    }),
    menu: (provided: any) => ({
      ...provided,
      maxHeight: `${menuHeight}px`,
      overflowY: "visible",
    }),
    menuList: (provided: any) => ({
      ...provided,
      maxHeight: `${menuHeight}px`,
      overflowY: "visible",
    }),
    option: (provided: any, state: any) => {
      return {
        ...provided,
        backgroundColor: state.isFocused ? "#FFF1E6" : "#FFFFFF",
        height: `${optionHeight}px`,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      };
    },
  };

  return (
    <Select
      id={instanceId}
      aria-label={instanceId}
      aria-labelledby={instanceId}
      name={instanceId}
      instanceId={instanceId}
      formatOptionLabel={formatOptions ? formatOptionLabel : undefined}
      tabIndex={tabIndex}
      ref={selectRef}
      styles={customStyles}
      placeholder={placeholder}
      className={`${select ? "basic-multi-select" : "basic-single"} ${
        designation ? "" : ""
      } h-100 w-100 search-field`}
      // defaultValue={valueToShow ? selectedOption : defaultValue}
      onMenuScrollToBottom={onEndReached}
      classNamePrefix="select"
      closeMenuOnSelect={!select}
      components={{
        ...(options.length === 0
          ? {
              Menu: () => null,
              IndicatorSeparator: () => null,
              DropdownIndicator,
            }
          : { NoOptionsMessage }),
        LoadingIndicator: CustomLoader,
      }}
      isMulti={select}
      options={options}
      // menuShouldScrollIntoView={true}
      menuPosition="fixed"
      value={value}
      inputValue={controlledInputValue}
      onBlur={onBlurHandler}
      onChange={onChangeHandler ? onChangeHandler : handleChange}
      onInputChange={handleInputChange}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isDisabled={disabled ? true : false}
      onMenuClose={menuCloseHandler}
      isLoading={isLoading}
      loadingMessage={loadingMessage}
      onFocus={onFocusHandler}
      onKeyDown={onKeyDownHandler}
      blurInputOnSelect={false}
      openMenuOnFocus={false}
    />
  );
};

export { CustomMultiSelect };
