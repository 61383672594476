"use client";

import ProductGridList from "../Listing/Product/ProductGridList";

const MultipleListing = ({
  config,
  widgetIndex,
}: {
  config: {
    content: {
      title: string;
      content: any[];
    }[];
  };
  widgetIndex: number;
  pageDetails?: {
    display_name: string;
    short_summary: string;
  };
}) => {
  return config.content.length > 0 ? (
    <section
      className="product-grid-lists section-padding-12 pb-15 bottom-cards"
      key={`mulitple-column-${widgetIndex}`}
    >
      <div className="container">
        <div className="row">
          {config.content.map((item: any, index: number) => (
            <ProductGridList
              widgetIndex={widgetIndex}
              key={`multiple-column-${index}`}
              title={item.title}
              products={item.content}
              delay=".3s"
            />
          ))}
        </div>
      </div>
    </section>
  ) : null;
};

export { MultipleListing };
