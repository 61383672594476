import * as Yup from "yup";

export const AddressSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  contact_number: Yup.string()
    .required("Mobile Number is required")
    .min(7, "Mobile Number should be more than 7 digits")
    .max(15, "Mobile Number should be less than 15 digits"),
  pincode: Yup.string().required("Pincode is required").nonNullable(),
  address_line_1: Yup.string().required(
    "Please enter flat/apartment details is required"
  ),
  address_line_2: Yup.string().required(
    "Please enter area/locality details is required"
  ),
  landmark: Yup.string().nullable(),
  city: Yup.string().required("City is required").nonNullable(),
  state: Yup.string().required("State is required").nonNullable(),
  country: Yup.string().required("Country is required").nonNullable(),
  address_type: Yup.string().nullable(),
  is_default: Yup.boolean(),
  // is_business: Yup.boolean(),
  // company_name: Yup.string().when("is_business", {
  //   is: true,
  //   then: (schema) =>
  //     schema.required("Company Name is required when business is selected"),
  //   otherwise: (schema) => schema.nullable(),
  // }),
  // gstin: Yup.string().when("is_business", {
  //   is: true,
  //   then: (schema) =>
  //     schema
  //       .required("GSTIN is required when business is selected")
  //       .matches(
  //         /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/,
  //         "GSTIN format is invalid"
  //       ),
  //   otherwise: (schema) => schema.nullable(),
  // }),
});

export const ProfileSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  contact_number: Yup.string()
    .required("Mobile Number is required")
    .min(7, "Mobile Number should be more than 7 digits")
    .max(15, "Mobile Number should be less than 15 digits"),
  email: Yup.string().email("Invalid email").required("Email is required"),
});

export const UpdateProfileDetailsSchema = ({
  otpSent,
  type,
}: {
  otpSent: boolean;
  type: string;
}) => {
  const valueToSet = `${type === "email" ? "Email" : "Contact Number"}`;

  const emailOrPhoneValidation = Yup.string()
    .required(`${valueToSet} is required`)
    .test("profile_email_or_phone", `Invalid ${valueToSet}`, (value) => {
      if (!value) return false;
      const isPhoneNumber = /^[0-9]{7,15}$/.test(value);
      const isEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(value);
      return type === "email" ? isEmail : isPhoneNumber;
    });

  return Yup.object().shape({
    old_value: emailOrPhoneValidation,
    new_value: emailOrPhoneValidation.test(
      "not_same_as_old_value",
      `Please enter a new ${valueToSet}`,
      function (new_value) {
        const { old_value } = this.parent;
        return new_value !== old_value;
      }
    ),
    otp: otpSent
      ? Yup.number().nonNullable().required("OTP is required")
      : Yup.number().nullable(),
  });
};

export const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  contact_number: Yup.string()
    .required("Mobile Number is required")
    .min(7, "Mobile Number should be more than 7 digits")
    .max(15, "Mobile Number should be less than 15 digits"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});

export const LoginSchema = ({
  verification_pending,
  otpSent,
}: {
  verification_pending: boolean;
  otpSent: boolean;
}) => {
  const emailOrPhoneValidation = Yup.string()
    .required("Email or Phone Number is required")
    .test("email_or_phone", "Invalid Email or Phone Number", (value) => {
      if (!value) return false;
      const isPhoneNumber = /^[0-9]{7,15}$/.test(value);
      const isEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(value);
      return isPhoneNumber || isEmail;
    });

  return Yup.object().shape({
    email_or_phone: emailOrPhoneValidation,
    email: verification_pending
      ? Yup.string()
          .email("Invalid email")
          .nonNullable()
          .required("Email is required")
      : Yup.string().email("Invalid email").nullable(),
    otp: otpSent
      ? Yup.number().nonNullable().required("OTP is required")
      : Yup.number().nullable(),
    country: Yup.array().when("email_or_phone", (value, schema) => {
      return /^[0-9]{10}$/.test(value?.[0] || "")
        ? schema.required("Country code is required")
        : schema.nullable();
    }),
  });
};

export const RegisterSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  contact_number: Yup.string()
    .required("Mobile Number is required")
    .min(7, "Mobile Number should be more than 7 digits")
    .max(15, "Mobile Number should be less than 15 digits"),
  // whatsapp_number: Yup.string()
  //   .required("Whatsapp Number is required")
  //   .max(10, "Whatsapp Number should be 10 digits")
  //   .min(10, "Whatsapp Number should be 10 digits"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  country: Yup.array().min(1, "Country code is required"),
});

export const ReviewSchema = Yup.object().shape({
  rating: Yup.number()
    .required("Please provide a valid rating")
    .min(1, "Please provide a valid rating"),

  review_comment: Yup.string().required("Review is required"),

  review_status: Yup.string().nullable(),

  review_media: Yup.array().of(Yup.string()).nullable(),
});

export const ApplyDiscountSchema = Yup.object().shape({
  code: Yup.string().nullable(),
});

export const LocalCartItemSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  id: Yup.number().required("ID is required"),
  payable_amount: Yup.number().required("Payable amount is required"),
  price: Yup.number().required("Price is required"),
  discount_value: Yup.number().required("Discount value is required"),
  discount_code: Yup.string().nullable(), // Nullable, if it's an empty string
  discount_type: Yup.string().required("Discount type is required"),
  rating: Yup.number().min(0).max(5).required("Rating is required"),
  quantity: Yup.number().min(1).required("Quantity is required"),
  oldPrice: Yup.number().required("Old price is required"),
  slug: Yup.string().required("Slug is required"),
  images: Yup.array()
    .of(
      Yup.object().shape({
        alt: Yup.string().required("Image alt text is required"),
        img: Yup.string()
          .url("Image must be a valid URL")
          .required("Image URL is required"),
      })
    )
    .min(1, "At least one image is required")
    .required("Images are required"),
  thumbnails: Yup.array()
    .of(
      Yup.object().shape({
        alt: Yup.string().required("Thumbnail alt text is required"),
        img: Yup.string()
          .url("Thumbnail must be a valid URL")
          .required("Thumbnail URL is required"),
      })
    )
    .min(1, "At least one thumbnail is required")
    .required("Thumbnails are required"),
  weights: Yup.array()
    // .of(
    //   Yup.object().shape({
    //     id: Yup.number().required("Weight ID is required"),
    //     value: Yup.number().required("Weight value is required"),
    //     label: Yup.number().required("Weight label is required"),
    //     uom: Yup.string().required("Unit of measure is required"),
    //   })
    // )
    // .min(1, "At least one weight is required")
    .required("Weights are required"),
  weight_id: Yup.number().required("Weight ID is required"),
  weight: Yup.number().required("Weight is required"),
  uom: Yup.string().required("Unit of measure is required"),
});

export const LocalCartSchema = Yup.array().of(LocalCartItemSchema);

export type ILocalCartItem = Yup.InferType<typeof LocalCartItemSchema>;
