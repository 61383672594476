import { combineReducers } from "redux";
import commonSlice from "./commonSlice";
import cartSlice from "./cartSlice";
import wishlistSlice from "./wishlistSlice";
import authSlice from "./authSlice";
import locationSlice from "./locationSlice";
import orderSlice from "./orderSlice";
import addressSlice from "./addressSlice";

const rootReducer = combineReducers({
  auth: authSlice,
  address: addressSlice,
  cart: cartSlice,
  common: commonSlice,
  location: locationSlice,
  order: orderSlice,
  wishlist: wishlistSlice,
});

export { rootReducer };
