"use client";

import React from "react";

const PaymentMethod = () => (
  <div className="payment_method">
    <div className="mb-15">
      <h5>Payment Method</h5>
    </div>
    <div className="payment_option">
      <div className="custome-radio">
        <input
          className="form-check-input"
          type="radio"
          name="payment_option"
          id="exampleRadios3"
          defaultChecked={true}
        />
        <label
          className="form-check-label"
          htmlFor="exampleRadios3"
          data-bs-toggle="collapse"
          data-target="#bankTranfer"
          aria-controls="bankTranfer"
        >
          Pay on Delivery
        </label>
        <div className="form-group collapse in" id="bankTranfer">
          <p className="text-muted mt-5">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration.
          </p>
        </div>
      </div>
      <div className="custome-radio">
        <input
          className="form-check-input"
          type="radio"
          name="payment_option"
          id="exampleRadios4"
          defaultChecked={true}
        />
        <label
          className="form-check-label"
          htmlFor="exampleRadios4"
          data-bs-toggle="collapse"
          data-target="#checkPayment"
          aria-controls="checkPayment"
        >
          Check Payment
        </label>
        <div className="form-group collapse in" id="checkPayment">
          <p className="text-muted mt-5">
            Please send your cheque to Store Name, Store Street, Store Town,
            Store State / County, Store Postcode.
          </p>
        </div>
      </div>
      <div className="custome-radio">
        <input
          className="form-check-input"
          type="radio"
          name="payment_option"
          id="exampleRadios5"
          defaultChecked={true}
        />
        <label
          className="form-check-label"
          htmlFor="exampleRadios5"
          data-bs-toggle="collapse"
          data-target="#paypal"
          aria-controls="paypal"
        >
          Paypal
        </label>
        <div className="form-group collapse in" id="paypal">
          <p className="text-muted mt-5">
            Pay via PayPal; you can pay with your credit card if you don't have
            a PayPal account.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export { PaymentMethod };
