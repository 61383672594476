"use client";

import React, { useCallback, useMemo, useState } from "react";
import {
  advancedDebounce,
  GENERAL_CONSTANTS,
  STATUSES,
  trackSearch,
  usePagination,
} from "@/utils";
import { CustomMultiSelect } from "./CustomMultiSelect";
import {
  fetchSearchResults,
  resetSearchResults,
  useAppDispatch,
  useAppSelector,
} from "@/store";
import { useRouter } from "next/navigation";

const Search = ({ instanceID }: { instanceID: string }) => {
  const dispatch = useAppDispatch();

  const router = useRouter();

  const { searchOptions, state } = useAppSelector((state) => state.common);

  const [searchTerm, setSearchTerm] = useState("");

  const { updateSearch } = usePagination();

  const getSearchResults = useMemo(() => {
    if (!searchOptions?.length) return [];

    const limitedOptions = searchOptions.slice(0, 10).map((product: any) => ({
      value: product.slug,
      label: product.title,
      updateSearchTerm: () => {},
    }));

    if (searchOptions.length > 10) {
      limitedOptions.push({
        value: `/search/?query=${searchTerm}&limit=12`,
        label: "See More ...",
        updateSearchTerm: () => {
          updateSearch(searchTerm);
        },
      });
    }

    return limitedOptions;
  }, [searchOptions?.length, state.status]);

  //Function for calling dispatch for Product search
  const searchValues = (query: string) => {
    if (query) {
      dispatch(resetSearchResults());
      dispatch(fetchSearchResults({ query: query }));
      trackSearch({ search_term: query });
    } else {
      return;
    }
  };

  //Debounce function for search
  const optimizeSearch = useCallback(advancedDebounce(searchValues), []);

  return (
    <CustomMultiSelect
      instanceId={instanceID}
      name="product-search"
      formatOptions={true}
      select={false}
      placeholder={"Search Avarya.in"}
      options={getSearchResults}
      value={
        searchTerm
          ? {
              value: searchTerm,
              label: searchTerm,
            }
          : null
      }
      inputValue={searchTerm}
      isLoading={
        state.status === STATUSES.LOADING &&
        state.type === "fetch-search-results"
      }
      onChangeHandler={(e, a) => {
        if (e !== null && e !== undefined) {
          e.value.includes("search")
            ? router.push(e.value)
            : router.push(`/${e.value}`);
        }
        if (a.action === GENERAL_CONSTANTS.CLEAR) {
          setSearchTerm("");
        }
        dispatch(resetSearchResults());
      }}
      //On key down event for enter key
      onKeyDownHandler={(e) => {
        if (e.key === GENERAL_CONSTANTS.ENTER) {
          e.stopPropagation();
          optimizeSearch(searchTerm);
        }
      }}
      onInputChangeHandler={(e) => {
        if (e.length === 0) {
          dispatch(resetSearchResults());
        }
        if (e.length > 3) {
          optimizeSearch(e);
        }
        setSearchTerm(e);
      }}
    />
  );
};

export { Search };
