import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  apiRequestClient,
  customRevalidateTag,
  formatProduct,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATUSES,
} from "@/utils";
import { setCommonState } from "./commonSlice";
import * as Sentry from "@sentry/nextjs";

const initialState = {
  status: STATUSES.IDLE as string,
  error: null as null | string,
  wishlistCounter: 0,
  wishlist: [] as any[],
  pendingItemsToAdd: [] as any[],
};

export const setWholeWishlist = createAsyncThunk(
  "wishlist/setWholeWishlist",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonState({ status: STATUSES.LOADING, type: "fetch-wishlist" })
    );
    dispatch(resetWishlist());

    const wishlistToSet: any[] = [];

    try {
      const response = await apiRequestClient({
        url: `/api/wishlist/`,
        method: REQUEST_METHOD.GET,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
      });

      for (let i in response.result.results) {
        try {
          const formattedProduct = formatProduct(
            response.result.results[i].product_details
          );

          const dataToAdd = {
            ...response.result.results[i],
            wishlist_id: response.result.results[i].id,
            ...formattedProduct,
          };

          delete dataToAdd["product_details"];

          wishlistToSet.push(dataToAdd);
        } catch (error: any) {
          Sentry.captureException(new Error(JSON.stringify(error)));
          rejectWithValue(error.message);
        }
      }

      dispatch(
        setCommonState({ status: STATUSES.IDLE, type: "fetch-wishlist" })
      );
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);

      dispatch(
        setCommonState({ status: STATUSES.ERROR, type: "fetch-wishlist" })
      );
    } finally {
      dispatch(setWishlistCounter(wishlistToSet?.length));
      return wishlistToSet;
    }
  }
);

export const addToWishlist = createAsyncThunk(
  "wishlist/addToWishlist",
  async (
    { product_id, onSucces }: { product_id: number; onSucces?: () => void },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      common: { filteredProducts },
    } = getState() as RootState;

    try {
      const response = await apiRequestClient({
        url: "/api/wishlist/",
        method: REQUEST_METHOD.POST,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        data: { product_id: product_id },
      });

      dispatch(
        addProductToWishlist({
          product: formatProduct(response.result.product_details),
          wishlist_id: response.result.id,
        })
      );

      dispatch(incrementWishlistCounter());
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    }
  }
);

export const removeFromWishlist = createAsyncThunk(
  "wishlist/removeFromWishlist",
  async (
    { wishlist_id, onSuccess }: { wishlist_id: number; onSuccess?: () => void },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      wishlist: { wishlist },
    } = getState() as RootState;

    try {
      const response = await fetch(`/api/wishlist/${wishlist_id}/`, {
        method: REQUEST_METHOD.DELETE,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res?.json());

      dispatch(decrementWishlistCounter());

      onSuccess && onSuccess();

      const index = wishlist.findIndex(
        (item: any) => item.wishlist_id === wishlist_id
      );

      dispatch(removeProductFromWishlist({ index: index }));
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
    }
  }
);

export const addProductToWishlist = createAsyncThunk(
  "wishlist/addProductToWishlist",
  async (
    { product, wishlist_id }: { product: any; wishlist_id?: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      wishlist: { wishlist },
    } = getState() as RootState;

    const wishlistToUse = JSON.parse(JSON.stringify(wishlist));

    const existingProductIndex = wishlistToUse.findIndex(
      (item: any) => item.id === product.id && item.weight === product.weight
    );

    if (existingProductIndex !== -1) {
      return wishlistToUse;
    }

    return [{ ...product, wishlist_id: wishlist_id }, ...wishlistToUse];
  }
);

export const removeProductFromWishlist = createAsyncThunk(
  "wishlist/removeProductFromWishlist",
  async (
    { index }: { index: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    const {
      wishlist: { wishlist },
    } = getState() as RootState;

    const wishlistToUse = JSON.parse(JSON.stringify(wishlist));

    if (index === -1) {
      return wishlistToUse;
    }

    wishlistToUse.splice(index, 1);

    return wishlistToUse;
  }
);

export const clearWishlist = createAsyncThunk(
  "wishlist/clearWishlist",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: `clear-wishlist`,
      })
    );
    try {
      const response = await fetch(`/api/wishlist/`, {
        method: REQUEST_METHOD.DELETE,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => res?.json());

      dispatch(setWishlistCounter(0));

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: `clear-wishlist`,
        })
      );

      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      rejectWithValue(error.message);
      dispatch(
        setCommonState({
          status: STATUSES.ERROR,
          type: `clear-wishlist`,
        })
      );
    }
  }
);

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    resetWishlist: (state) => {
      state = initialState;
    },
    setWishlistCounter: (state, action) => {
      state.wishlistCounter = action.payload;
    },
    incrementWishlistCounter: (state) => {
      state.wishlistCounter += 1;
    },
    decrementWishlistCounter: (state) => {
      state.wishlistCounter -= 1;
    },
    resetWishlistState: (state) => {
      state.wishlist = [];
    },
    addPendingItem: (state, action) => {
      state.pendingItemsToAdd.push(action.payload);
    },
    resetPendingItemsToAdd: (state) => {
      state.pendingItemsToAdd = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(setWholeWishlist.fulfilled, (state, action) => {
        state.wishlist = action.payload;
      })
      .addCase(addProductToWishlist.fulfilled, (state, action) => {
        state.wishlist = action.payload;
      })
      .addCase(removeProductFromWishlist.fulfilled, (state, action) => {
        state.wishlist = action.payload;
      })
      .addCase(addToWishlist.fulfilled, (state, action) => {
        action.meta.arg.onSucces && action.meta.arg.onSucces();
      })
      .addCase(clearWishlist.fulfilled, (state, action) => {
        state.wishlist = [];
      })
      .addMatcher(
        isAnyOf(addToWishlist.pending, removeFromWishlist.pending),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        isAnyOf(addToWishlist.fulfilled, removeFromWishlist.fulfilled),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      )
      .addMatcher(
        isAnyOf(
          // addToWishlist.fulfilled,
          removeFromWishlist.fulfilled,
          clearWishlist.fulfilled
        ),
        () => {
          // customRevalidateTag("page-data");
          customRevalidateTag("wishlist");
        }
      );
  },
});

export const {
  resetWishlist,

  setWishlistCounter,
  incrementWishlistCounter,
  decrementWishlistCounter,

  resetWishlistState,
  addPendingItem,
  resetPendingItemsToAdd,
} = wishlistSlice.actions;

export default wishlistSlice.reducer;
