import {
  apiRequestClient,
  formatProductForOrder,
  IOrderDetail,
  printBill,
  REQUEST_CONTENT_TYPE,
  REQUEST_METHOD,
  STATUSES,
  Toast,
  TOAST_CONSTANTS,
} from "@/utils";
import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { setCommonState, setControllerState } from "./commonSlice";
import * as Sentry from "@sentry/nextjs";
import { RootState } from "../store";

const initialState = {
  successfulOrder: {} as IOrderDetail,
  shippingCost: 0,
  shippingLog: 0,
  status: STATUSES.IDLE,
  proceedToCheckout: false,
  error: null as null | string,
};

export const fetchSuccessfulOrder = createAsyncThunk(
  "order/fetchSuccessfulOrder",
  async ({
    order_id,
    onSuccess,
  }: {
    order_id: number;
    onSuccess: (value: IOrderDetail) => void;
  }) => {
    try {
      const response = await apiRequestClient({
        url: `/api/order/${order_id}/`,
        method: REQUEST_METHOD.GET,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
      });

      const orderToSet = {
        ...response.result,
        ordered_products: response.result.ordered_products.map((item: any) => ({
          ...item,
          product: {
            ...formatProductForOrder(item.product),
            selected_weight: item.product.product_weights?.find(
              (weight: any) => weight.id === item.product_weight
            ).weight,
          },
        })),
      };

      onSuccess(orderToSet);

      return orderToSet;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      return null;
    }
  }
);

export const printOrderInvoice = createAsyncThunk(
  "order/printOrderInvoice",
  async ({ order_id }: { order_id: number }, { rejectWithValue }) => {
    const response = await fetch(`/api/order/print/${order_id}/`, {
      method: REQUEST_METHOD.GET,
      headers: {
        "Content-Type": REQUEST_CONTENT_TYPE.APP_JSON,
      },
    }).then((res) => res?.json());

    printBill(response);
  }
);

export const fetchShippingCost = createAsyncThunk(
  "order/fetchShippingCost",
  async (
    { addressId }: { addressId: number },
    { dispatch, getState, rejectWithValue }
  ) => {
    dispatch(
      setCommonState({
        status: STATUSES.LOADING,
        type: "shipping-cost",
      })
    );

    const {
      common: { controller: abortController },
    } = getState() as RootState;

    if (abortController) {
      abortController.abort();
    }

    const controller = new AbortController();
    dispatch(setControllerState(controller));

    try {
      const response = await apiRequestClient({
        url: `/api/order/shipping-cost/?id=${addressId}`,
        method: REQUEST_METHOD.GET,
        contentType: REQUEST_CONTENT_TYPE.APP_JSON,
        showToast: false,
        signal: controller.signal,
      });

      dispatch(
        setCommonState({
          status: STATUSES.IDLE,
          type: "shipping-cost",
        })
      );
      return response;
    } catch (error: any) {
      Sentry.captureException(new Error(JSON.stringify(error)));
      // dispatch(
      //   setCommonState({
      //     status: STATUSES.ERROR,
      //     type: "shipping-cost",
      //   })
      // );
      rejectWithValue(error.message);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    resetShippingDetails: (state) => {
      state.shippingCost = 0;
      state.shippingLog = 0;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSuccessfulOrder.fulfilled, (state, action) => {
        state.successfulOrder = action.payload;
      })
      .addCase(fetchShippingCost.fulfilled, (state, action: any) => {
        if (action.payload) {
          state.shippingCost = action.payload?.result?.price || 0;
          state.shippingLog = action.payload?.result?.shipping_log;
        } else {
          // Toast({
          //   message: "Error occured while fetching shipping cost",
          //   type: TOAST_CONSTANTS.ERROR,
          // });
          state.proceedToCheckout = true;
        }
      })
      .addMatcher(
        isAnyOf(printOrderInvoice.pending, fetchSuccessfulOrder.pending),
        (state) => {
          state.status = STATUSES.LOADING;
        }
      )
      .addMatcher(
        isAnyOf(printOrderInvoice.fulfilled, fetchSuccessfulOrder.fulfilled),
        (state) => {
          state.status = STATUSES.IDLE;
        }
      );
  },
});

export const { resetShippingDetails } = orderSlice.actions;

export default orderSlice.reducer;
